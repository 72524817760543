import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 56px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  padding-right: 111px;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 1280px) {
    padding: 48px 16px;
  }
`;

const HeroImg = styled.img`
  margin-bottom: 72px;
  max-width: 604px;
  width: 100%;
  @media (max-width: 1280px) {
    width: 100%;
    margin-bottom: 56px;
  }
`;

const Question = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 143%;
`;
const Answer = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 39px;
  font-size: 16px;
  font-family: Georgia, serif;
  line-height: 143%;
`;

export default function Landing() {
  return (
    <Container>
      <HeroImg srcSet="hero.jpg 2x" alt="Reply" />
      <Question>What is Rep.ly?</Question>
      <Answer>
        Rep.ly is a question and answer platform. On Rep.ly, you can create your
        own rep.ly page to answer questions in public from anyone you share your
        link with. It’s free with no ads. Just questions and answers. If you’d
        rather ask questions than answer them, that’s fine too. Rep.ly is a mix
        of people asking questions and others answering them. Just go to
        someone's page, type in your question and ask away. There are no
        guaranteed replies, but your chances just went up 10x.
      </Answer>

      <Question>Why should I create a page?</Question>
      <Answer>
        Answering questions is a great way for people to get to know you in an
        honest and transparent way. Maybe you find yourself answering the same
        questions from people all the time and want one place for people to find
        common answers about you. Or maybe you’re just bored and want to answer
        some interesting questions from your friends and followers. With Rep.ly,
        you can answer questions at your own pace and have fun in the process.
        It’s your page to use however you’d like.
      </Answer>

      <Question>How do I create a page?</Question>
      <Answer>
        To create a rep.ly page, you just need a Twitter account. All sign ups
        are handled through Twitter, meaning your name and profile picture on
        Twitter will be used on your rep.ly page. It takes less than 5 seconds
        to sign up, and don’t worry, we won’t tweet for you or read your DM’s.
        Twitter is only used for signing up and logging in.
      </Answer>

      <Question>Can people ask anonymous questions?</Question>
      <Answer>
        We allow people to ask questions anonymously once they have an account,
        which hides their name from you and everyone else, but not from us. We
        do this to prevent bullying and harassment, so if someone asks a
        question that rubs you the wrong way, you can report it and we’ll look
        into it. We respect all viewpoints but do not tolerate hurtful language
        or harassment of any kind. Accounts found to be in violation of this
        will be banned. With that said, although we can technically see who asks
        an anonymous question if we really want to, we will never look unless a
        report is made.
      </Answer>

      <Question>How does Rep.ly make money?</Question>
      <Answer>
        It doesn’t, but we’re not too bothered about that right now. We made
        this service because we wanted to use it ourselves. That might not be
        the case if it cost money or had a bunch of ads, so for now it’s free.
        Yay free stuff!
      </Answer>
    </Container>
  );
}
