import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import {
  Post,
  Question,
  Answer,
  AnsweredYour,
  PostInfoSingleLine,
  AskedYou,
  QuestionPure,
  MentionedYou,
} from './UI/Timeline';

import { useGlobalState } from '../GlobalState';

const Container = styled(motion.div)`
  position: absolute;
  overflow-y: scroll;
  top: 121px;
  right: 384px;
  padding: 24px;
  width: 408px;
  max-height: 522px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Title = styled(motion.div)`
  color: #000;
  font-weight: 500;
`;

const EmptyState = styled(motion.div)`
  padding: 24px;
  width: 100%;
  text-align: center;
`;

export default function Activity({ visible, forwardRef }) {
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    dispatch({ type: 'LOAD_ACTIVITY' });
  }, []);

  return (
    <Container
      initial={false}
      animate={{
        opacity: visible ? 1 : 0,
        scale: visible ? 1 : 0.98,
        x: visible ? 0 : 10,
        pointerEvents: visible ? 'auto' : 'none',
      }}
      transition={{
        type: 'spring',
        mass: 0.1,
        damping: 20,
        stiffness: 220,
      }}
      ref={forwardRef}
    >
      {state.activity.length === 0 && !state.activityLoading ? (
        <EmptyState>
          <QuestionPure>Nothing to see here.</QuestionPure>
          <Answer>You'll see any new activity here.</Answer>
        </EmptyState>
      ) : !state.activityLoading ? (
        <Title>Latest Activity</Title>
      ) : null}
      {state.activity.map((post) => {
        const date = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }).format(new Date(post.updatedAt));

        return (
          <Post
            key={post._id}
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.05)',
              marginBottom: 0,
              marginTop: 24,
              paddingTop: 24,
            }}
          >
            <PostInfoSingleLine>{date}</PostInfoSingleLine>
            <Question
              to={
                post.answer ? `/answer/${post._id}` : `/${state.user.username}`
              }
            >
              {post.question}
            </Question>
            {post.answer && post.to._id !== state.user._id ? (
              <Link
                to={`/answer/${post._id}`}
                style={{
                  color: '#000',
                  textDecoration: 'none',
                }}
              >
                <Answer
                  style={{ marginBottom: 16 }}
                  text={post.answer}
                  truncate={24}
                />
              </Link>
            ) : null}
            {post?.mentionedUsers.includes(state.user._id) ? (
              <MentionedYou
                style={{ marginTop: 8 }}
                to={`/${post.to.username}`}
                imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${post.to.username}/${post.to.s3AvatarKey}.jpg`}
                displayName={post.to.displayName}
              />
            ) : post.to._id === state.user._id ? (
              <AskedYou
                style={{ marginTop: 8 }}
                to={`/${post.from.username}`}
                imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${post.from.username}/${post.from.s3AvatarKey}.jpg`}
                displayName={post.from.displayName}
                post={post}
              />
            ) : (
              <AnsweredYour
                style={{ marginTop: 8 }}
                to={`/${post.to.username}`}
                imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${post.to.username}/${post.to.s3AvatarKey}.jpg`}
                displayName={post.to.displayName}
              />
            )}
          </Post>
        );
      })}
    </Container>
  );
}
