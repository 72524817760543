import React from 'react';
import styled from 'styled-components';
import TextareaAutosizeRaw from 'react-textarea-autosize';
import TextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import ky from 'ky';

const QuestionTextarea = styled(TextareaAutocomplete)`
  padding: 4px 0 8px 0;
  outline: none;
  width: 100%;
  min-height: 32px;
  max-width: 604px;
  font-size: 16px;
  font-weight: 500;
  font-family: Georgia, serif;
  border: 0;
  resize: none;
  line-height: 22px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

const TextareaAutosize = React.forwardRef((props, ref) => (
  <TextareaAutosizeRaw inputRef={ref} {...props} />
));

const AutocompleteContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 12px;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const AutocompleteSuggestion = ({ entity, selected }) => {
  return (
    <AutocompleteContainer
      style={{ backgroundColor: selected ? '#F8F8F8' : 'transparent' }}
    >
      <img
        src={`${process.env.REACT_APP_ACCELERATOR_URL}/${entity.username}/${entity.s3AvatarKey}.jpg`}
      />

      <p>{entity.displayName}</p>
    </AutocompleteContainer>
  );
};

const Composer = (props) => {
  return (
    <QuestionTextarea
      textAreaComponent={TextareaAutosize}
      trigger={{
        '@': {
          dataProvider: async (token) => {
            const results = (await ky(`/v1/user/search/${token}`).json()).slice(
              0,
              4
            );

            // Each list item is 40px tall.
            const offset = 36 + results.length * 40;
            document.documentElement.style.setProperty(
              '--autocomplete-margin-top',
              '-' + offset + 'px'
            );

            return results;
          },
          output: (item) => '@' + item.username,
          component: AutocompleteSuggestion,
        },
      }}
      loadingComponent={() => null}
      {...props}
    />
  );
};

export default Composer;
