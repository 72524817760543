import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { isBrowser, isMobile } from 'react-device-detect';
import Linkify from 'react-linkify';
import reactStringReplace from 'react-string-replace';
import ky from 'ky';

import { Link } from 'react-router-dom';

import Tooltip from './Tooltip';
import { LinkMutedRouter } from '../Link';
import { truncate } from '../helpers/utils';

const Post = styled(motion.div)`
  position: relative;
  margin-bottom: 56px;
`;

const Question = styled(Link)`
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: bold;
  line-height: 143%;
  text-decoration: none;
  word-break: break-all;
`;

const QuestionPure = styled(motion.div)`
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: bold;
  line-height: 143%;
  text-decoration: none;
`;

const AnswerContainer = styled(motion.div)`
  margin-top: 14px;
  white-space: pre-wrap;
  word-break: break-word;

  &,
  strong,
  em {
    font-size: 16px;
    font-family: Georgia, serif;
    line-height: 22px;
  }

  & a {
    color: #111924;
    font-family: Georgia, serif;
    text-decoration: none;
    box-shadow: 0 1px 0 rgba(17, 25, 36, 0.2);
    &:hover {
      box-shadow: 0 1px 0 rgba(17, 25, 36, 1);
    }
  }

  @media (max-width: 1280px) {
    margin-top: 8px;
  }
`;

const EditedBadge = styled.div`
  cursor: default;
  display: inline-block;
  opacity: 0.5;
  font-size: 12px;
  margin-left: 4px;
  line-height: 20px;
`;

const InlineMentionContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  box-shadow: none !important;
  margin: 0 4px;
  height: 24px;

  img {
    margin: 0 6px 0 6px;
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.009em;
  }

  &:hover {
    /* opacity: 0.6; */
    /* transition: 0.1s ease opacity; */
  }
`;

const InlineMention = ({ match }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    ky(`/v1/user/${match}`)
      .json()
      .then((res) => setData(res));
  }, []);

  if (!data) return '@' + match;

  return (
    <motion.div
      initial={false}
      style={{ display: 'inline-grid', height: 18, borderRadius: 100 }}
      animate={{
        width: !data ? 120 : 'auto',
        background: !data ? '#fafafa' : '#fff',
      }}
      transition={{
        duration: 0.4,
      }}
    >
      <motion.div
        initial={false}
        style={{
          display: 'flex',
        }}
        animate={{
          opacity: data ? 1 : 0,
        }}
        transition={{ delay: 0.4 }}
      >
        <InlineMentionContainer to={`/${data?.user?.username}`}>
          <AnsweredByAvatar
            src={`${process.env.REACT_APP_ACCELERATOR_URL}/${data?.user?.username}/${data?.user?.s3AvatarKey}.jpg`}
          />
          <p>{data?.user?.displayName}</p>
        </InlineMentionContainer>
      </motion.div>
    </motion.div>
  );
};

const EmbeddedPostContainer = styled.section`
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 18px 24px;
  margin: 16px 0;
  position: relative;
  background-color: var(--background-color);

  transition: border-color 0.1s, background-color 0.2s;
  transition-timing-function: ease;

  --hover-fade-opacity: 0;
  --background-color: white;

  :hover {
    border-color: #ccc;

    --hover-fade-opacity: 1;
    --background-color: #fafafa;
  }

  h1 {
    font-size: 16px;
    font-weight: bold;
    line-height: 143%;
    margin: 0;
    margin-bottom: 8px;
  }

  a {
    box-shadow: none !important;
  }
`;

const EmbeddedPostFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  margin-top: 16px;
  transition: background-color 0.2s;
  background-color: var(--background-color);

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  > a {
    /* Remove margin-top on AnsweredBy */
    margin-top: 0;
  }
`;

const EmbeddedPostButton = styled.button`
  background: transparent;
  border: none;
  font-size: 13px;
  outline: none;
  cursor: pointer;

  @media (max-width: 500px) {
    display: none;
  }
`;

const EmbeddedPostFade = styled.div`
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  width: calc(100% - 48px);
  height: 50px;
  margin-top: -50px;
  z-index: 2;
  position: absolute;

  :after {
    content: '';
    background: linear-gradient(
      to top,
      rgba(250, 250, 250, 1),
      rgba(250, 250, 250, 0)
    );
    width: 100%;
    height: 50px;
    position: absolute;
    transition: opacity 0.15s;
    opacity: var(--hover-fade-opacity);
  }
`;

const EmbeddedPost = ({ id }) => {
  const [data, setData] = useState({});
  const [hidingFull, setHidingFull] = useState(false);
  const [longAnswer, setLongAnswer] = useState(false);

  useEffect(() => {
    ky(`/v1/answer/single/${id}`)
      .json()
      .then((res) => {
        setData(res);

        if (res.answer.length > 300) {
          setHidingFull(true);
          setLongAnswer(true);
        }
      });
  }, []);

  if (!data._id) return null;

  return (
    <EmbeddedPostContainer>
      <Link to={`/answer/${id}`}>
        <h1>{data.question}</h1>

        <Answer text={data.answer} embedded={true} hideFull={hidingFull} />

        {hidingFull && <EmbeddedPostFade />}
      </Link>

      <EmbeddedPostFooter>
        <AnsweredBy
          imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${data.to.username}/${data.to.s3AvatarKey}.jpg`}
          displayName={data.to.displayName}
          to={`/${data.to.username}`}
        />

        {longAnswer && (
          <EmbeddedPostButton onClick={() => setHidingFull((value) => !value)}>
            {hidingFull === true ? 'Show' : 'Hide'} Full Answer
          </EmbeddedPostButton>
        )}
      </EmbeddedPostFooter>
    </EmbeddedPostContainer>
  );
};

const Answer = ({
  text,
  children,
  edited = false,
  data,
  truncate: truncateLength,
  embedded = false,
  hideFull = false,
}) => {
  // The `truncate` prop indicates the maximum number of characters that should be shown.

  // For legacy usage of Answer component

  if (children) {
    return <AnswerContainer>{children}</AnswerContainer>;
  }

  let processedAnswer = text;

  if (truncateLength) {
    processedAnswer = truncate(text, truncateLength);
  }

  processedAnswer = reactStringReplace(
    processedAnswer,
    // Inline mentions match this sequence: [start of string OR space] ["@" symbol] [alphanumeric characters]
    /(?:^|\s)\@([A-Za-z0-9_]+)/g,
    (match, i) => <InlineMention key={i} match={match} />
  );

  if (embedded === false) {
    // Otherwise, if you embed a post in itself, the tree will keep rendering forever.
    processedAnswer = reactStringReplace(
      processedAnswer,
      /(?:\n+)?(?:https?:\/\/)?rep\.ly\/answer\/([a-z0-9]+)(?:\n+)?/g,
      (match, i) => <EmbeddedPost key={i} id={match} />
    );
  }

  processedAnswer = reactStringReplace(
    processedAnswer,
    /(?:https?:\/\/)?rep\.ly\/([A-Za-z0-9_]+)/g,
    (match, i) => <InlineMention key={i} match={match} />
  );

  processedAnswer = reactStringReplace(
    processedAnswer,
    /\*_(.+?)_\*/g,
    (match, i) => (
      <strong key={i}>
        <em>{match}</em>
      </strong>
    )
  );

  processedAnswer = reactStringReplace(
    processedAnswer,
    /_\*(.+?)\*_/g,
    (match, i) => (
      <strong key={i}>
        <em>{match}</em>
      </strong>
    )
  );

  processedAnswer = reactStringReplace(
    processedAnswer,
    /\*(.+?)\*/g,
    (match, i) => <strong key={i}>{match}</strong>
  );

  processedAnswer = reactStringReplace(
    processedAnswer,
    /_(.+?)_/g,
    (match, i) => <em key={i}>{match}</em>
  );

  let yearDay, hourMinute;

  if (data && data.answeredAt) {
    yearDay = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(data.answeredAt));

    hourMinute = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(data.answeredAt));
  }

  return (
    <AnswerContainer
      initial={false}
      animate={hideFull ? 'hidden' : 'visible'}
      style={{
        height: 0,
      }}
      variants={{
        hidden: {
          overflow: 'hidden',
          height: 'auto',
        },
        visible: {
          overflow: 'visible',
          height: 'auto',
        },
      }}
      transition={{
        type: 'spring',
        mass: 0.1,
        damping: 20,
        stiffness: 220,
      }}
    >
      <Linkify>{processedAnswer}</Linkify>

      {edited && data ? (
        <Tooltip label={`${yearDay} at ${hourMinute}`} top={-24} left={24}>
          <EditedBadge>(edited)</EditedBadge>
        </Tooltip>
      ) : null}
    </AnswerContainer>
  );
};

const PostSidebar = styled.div`
  position: absolute;
  top: 0;
  left: -72px;
  transform: translate3d(-100%, 0, 0);
  margin-top: 3px;

  text-align: right;

  @media (max-width: 1280px) {
    position: static;
    transform: translate3d(0, 0, 0);

    margin-bottom: 8px;
    text-align: left;
  }
`;

const PostInfoContainer = styled.div`
  color: rgba(0, 0, 0, 0.5);
  line-height: 150%;
`;

const PostInfoSingleLine = styled(motion.div)`
  margin-bottom: 9px;
  color: rgba(0, 0, 0, 0.5);
`;

const AnsweredByContainer = styled(Link)`
  margin-top: 11px;
  min-height: 32px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  line-height: 1.4;
  font-size: 13px;
`;

const AnsweredByContainerPure = styled(motion.div)`
  margin-top: 8px;
  min-height: 32px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  line-height: 1.4;
`;

const AnsweredByAvatar = styled.img`
  display: block;
  margin: 0 10px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const AnsweredByName = styled.div`
  color: #000;
  font-weight: 600;
`;

const AnsweredBy = ({ imageUrl, displayName, to }) => {
  return (
    <AnsweredByContainer to={to}>
      <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>Answered by</span>
      <AnsweredByAvatar src={imageUrl} alt={displayName} />{' '}
      <AnsweredByName>{displayName}</AnsweredByName>
    </AnsweredByContainer>
  );
};

const AnsweredByPure = ({ imageUrl, displayName, to }) => {
  return (
    <AnsweredByContainerPure>
      Answered by <AnsweredByAvatar src={imageUrl} alt={displayName} />{' '}
      <AnsweredByName>{displayName}</AnsweredByName>
    </AnsweredByContainerPure>
  );
};
const AnsweredYour = ({ imageUrl, displayName, to, ...rest }) => {
  return (
    <AnsweredByContainer to={to} {...rest}>
      <AnsweredByAvatar
        src={imageUrl}
        alt={displayName}
        style={{ marginLeft: 0 }}
      />
      <AnsweredByName style={{ marginRight: 8 }}>{displayName}</AnsweredByName>{' '}
      answered your question
    </AnsweredByContainer>
  );
};

const AskedYou = ({ imageUrl, displayName, to, post, ...rest }) => {
  if (post.anonymous) {
    return (
      <AnsweredByContainerPure style={{ marginTop: 8, minHeight: 'auto' }}>
        {displayName} asked you a question
      </AnsweredByContainerPure>
    );
  } else {
    return (
      <AnsweredByContainer to={to} {...rest}>
        <AnsweredByAvatar
          src={imageUrl}
          alt={displayName}
          style={{ marginLeft: 0 }}
        />
        <AnsweredByName style={{ marginRight: 8 }}>
          {displayName}
        </AnsweredByName>{' '}
        asked you a question
      </AnsweredByContainer>
    );
  }
};

const MentionedYou = ({ imageUrl, displayName, to, ...rest }) => {
  return (
    <AnsweredByContainer to={to} {...rest}>
      <AnsweredByAvatar
        src={imageUrl}
        alt={displayName}
        style={{ marginLeft: 0 }}
      />
      <AnsweredByName style={{ marginRight: 8 }}>{displayName}</AnsweredByName>{' '}
      mentioned you in their answer
    </AnsweredByContainer>
  );
};

const PostInfo = ({ date, username, displayName, showFAQBadge }) => {
  if (showFAQBadge) {
    return (
      <PostInfoContainer>
        <FAQBadge />
      </PostInfoContainer>
    );
  }

  return (
    <PostInfoContainer>
      {date}
      {isBrowser ? <br /> : null}
      {isMobile ? <span style={{ margin: '0 4px' }}> · </span> : null}
      From{' '}
      {username === 'anonymous' ? (
        displayName
      ) : (
        <LinkMutedRouter
          to={`/${username}`}
          style={{
            // display: 'inline-block', // TODO: Figure out some alt way to do this
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 130,
            whiteSpace: 'nowrap',
            transform: isBrowser ? 'translateY(5px)' : 'translateY(0px)',
          }}
        >
          {displayName}
        </LinkMutedRouter>
      )}
    </PostInfoContainer>
  );
};

const Tabs = styled(motion.div)`
  margin-bottom: 56px;
  display: flex;
  width: 600px;
  margin-left: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  @media (max-width: 1280px) {
    margin-bottom: 48px;
    width: 100%;
  }
`;

const Tab = styled(motion.div)`
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 21px;
  transform: translateY(1px);
  color: ${(props) => (props.active ? '#000' : 'rgba(0, 0, 0, 0.5)')};
  border-bottom: ${(props) => (props.active ? 1 : 0)}px solid #000;
  user-select: none;
`;

const FAQBadge = () => {
  return (
    <div
      style={{
        backgroundColor: '#f2f2f2',
        padding: '4px 8px',
        maxWidth: 'max-content',
        borderRadius: 99,
        marginLeft: -4,
      }}
    >
      <p
        style={{
          color: '#808080',
          fontSize: 10,
          fontWeight: 600,
          lineHeight: 1,
          margin: 0,
        }}
      >
        FAQ
      </p>
    </div>
  );
};

export {
  Post,
  PostSidebar,
  PostInfo,
  PostInfoSingleLine,
  AnsweredBy,
  AnsweredByPure,
  AnsweredYour,
  Question,
  QuestionPure,
  Answer,
  AskedYou,
  Tabs,
  Tab,
  FAQBadge,
  MentionedYou,
};
