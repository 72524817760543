import React, { Suspense } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import { RecoilRoot } from 'recoil';

import { GlobalProvider } from './GlobalState';

import ProfilePage from './old_components/ProfilePageV2';
import SingleAnswerPage from './old_components/SingleAnswerPage';
import Toast from './old_components/Toast';
import TwitterModal from './old_components/TwitterModal';
import AuthObserver from './old_components/AuthObserver';

import HomePage from './pages/Home';
import FAQPage from './pages/FAQ';
import PrivacyPage from './pages/Privacy';
import AboutPage from './pages/About';
import TermsPage from './pages/Terms';

import Sidebar from './old_components/SidebarV2';
import MobileNav from './old_components/mobile/MobileNav';

import './App.css';

const Container = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1008px 432px;
  @media (max-width: 1280px) {
    grid-template-columns: 100%;
  }
`;

const PageContent = styled.div``;

function App() {
  return (
    <RecoilRoot>
      <GlobalProvider>
        <AuthObserver />
        <Toast />
        <Router>
          {isMobile ? <MobileNav /> : null}
          <TwitterModal />
          <Container>
            <PageContent>
              <Switch>
                <Route path="/" exact children={<HomePage />} />
                <Route path="/about" exact children={<AboutPage />} />
                <Route path="/faq" exact children={<FAQPage />} />
                <Route path="/privacy" exact children={<PrivacyPage />} />
                <Route path="/terms" exact children={<TermsPage />} />
                <Route path="/answer/:_id" children={<SingleAnswerPage />} />
                <Route path="/:username" children={<ProfilePage />} />
                <Route path="/:username/faq" children={<ProfilePage />} />
              </Switch>
            </PageContent>
            {isBrowser ? <Sidebar /> : null}
          </Container>
        </Router>
      </GlobalProvider>
    </RecoilRoot>
  );
}

export default App;
