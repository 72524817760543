import React, { useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { twitterModalState } from '../state/_index';

import { useGlobalState } from '../GlobalState';
import { LinkMutedRouter } from './Link';

const TwitterModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transition: 0.2s ease all;
  transition-delay: ${(props) => (props.visible ? 0 : 0.1)}s;
  z-index: 3;
`;

const TwitterModalContent = styled.div`
  padding: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -117px 0 0 -192px;
  width: 384px;
  background: #fff;
  border-radius: 8px;
  z-index: 4;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  transform: translateY(${(props) => (props.visible ? 0 : 10)}px);
  transition: 0.2s ease all;

  @media (max-width: 1100px) {
    padding: 24px;
    width: 343px;
    margin: -118px 0 0 -171px;
  }
`;

const Title = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const Desc = styled.div`
  margin-top: 7px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 143%;
`;

const SignIn = styled.button`
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  outline: none;
  margin: 16px 0;

  color: #1da1f2;
  background: linear-gradient(
      0deg,
      rgba(29, 161, 242, 0.1),
      rgba(29, 161, 242, 0.1)
    ),
    #ffffff;
  border: 1px solid rgba(29, 161, 242, 0.5);
  box-shadow: 0px 1px 2px rgba(29, 72, 119, 0.06);
  border-radius: 6px;
`;

export default function TwitterModal() {
  const [state, dispatch] = useGlobalState();
  const [visible, setVisible] = useRecoilState(twitterModalState);

  function handleLogin() {
    window.open('/auth/twitter', '_self');
  }

  return (
    <>
      <TwitterModalContent visible={state.twitterModalVisible}>
        <Title>Continue with Twitter</Title>
        <Desc>
          To use Rep.ly, continue with your Twitter account. We’ll never tweet
          without permission.
        </Desc>
        <SignIn onClick={handleLogin}>
          <img
            src="icons/twitter.svg"
            alt="Twitter"
            style={{ marginRight: 8, marginLeft: -8 }}
          />
          Continue with Twitter
        </SignIn>
        <Desc style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
          We use Twitter for authentication to gather your name, username and
          profile picture. See{' '}
          <LinkMutedRouter
            to="/faq"
            onClick={() => dispatch({ type: 'HIDE_TWITTER_MODAL' })}
          >
            FAQs
          </LinkMutedRouter>
          .
        </Desc>
      </TwitterModalContent>
      <TwitterModalOverlay
        visible={state.twitterModalVisible}
        onClick={() => dispatch({ type: 'HIDE_TWITTER_MODAL' })}
      ></TwitterModalOverlay>
    </>
  );
}
