import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FeaturedPost = styled(Link)`
  display: block;
  padding: 20px 24px 16px 24px;
  border: 1px solid #efefef;
  border-radius: 8px;
  text-decoration: none;
  margin-bottom: 48px;
  transition: border-color 0.1s, background-color 0.2s;
  transition-timing-function: ease;

  &:hover {
    border-color: #ccc;
    background-color: #fafafa;
  }

  @media (max-width: 1280px) {
    margin-bottom: 32px;
  }
`;

const FeaturedPostTitle = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;
`;

export { FeaturedPost, FeaturedPostTitle };
