import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { useGlobalState } from '../GlobalState';

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  background: #3fc53c;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: #fff;
`;

export default function Toast() {
  const [state, dispatch] = useGlobalState();
  useEffect(() => {
    let timeout;
    if (state.toastVisible) {
      timeout = setTimeout(() => {
        dispatch({ type: 'HIDE_TOAST' });
      }, 1500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [state.toastMessage, state.toastVisible]);

  return (
    <Container
      initial={false}
      animate={{
        y: state.toastVisible ? 0 : -30,
      }}
      transition={{
        type: 'spring',
        mass: 0.4,
        damping: 20,
        stiffness: 200,
      }}
    >
      {state.toastMessage}
    </Container>
  );
}
