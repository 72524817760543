import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { useGlobalState } from '../GlobalState';
import { LinkMutedRouter } from '../old_components/Link';
import { Tab, Tabs, TabList, TabPanel } from '../old_components/UI/Tabs';
import {
  Post,
  PostInfo,
  Question,
  Answer,
  AnsweredBy,
  AnsweredByPure,
  AnsweredYour,
  QuestionPure,
} from '../old_components/UI/Timeline';

import {
  FeaturedPost,
  FeaturedPostTitle,
} from '../old_components/UI/FeaturedPost';
import {
  SearchComponent,
  SearchContainer,
  SearchResultComponent,
} from '../old_components/UI/Search';
import Timeline from '../old_components/Timeline';

const Container = styled(motion.div)`
  margin-left: auto;
  padding: 62px 72px 72px 72px;
  max-width: 600px;
  box-sizing: content-box;
  @media (max-width: 1280px) {
    padding: 32px 16px;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 48px;
`;

export default function Home() {
  const [state, dispatch] = useGlobalState();
  const featIndex = useMemo(
    () => Math.floor(Math.random() * state.featured.length),
    [state.featured.length]
  );

  useEffect(() => {
    dispatch({ type: 'LOAD_RECENT' });
    dispatch({ type: 'LOAD_FEATURED' });
  }, []);

  useEffect(() => {
    if (state.user) {
      dispatch({ type: 'LOAD_FORYOU' });
    }
  }, [state.user]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SET_SEARCH_LOADING', payload: false });
      dispatch({ type: 'SET_SEARCH_RESULTS', payload: [] });
    };
  }, [dispatch]);

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity:
          state.featured.length === 0 || state.recent.length === 0 ? 0 : 1,
      }}
    >
      <Header>
        <Link
          style={{
            fontWeight: 600,
            fontSize: 16,
            userSelect: 'none',
            color: '#000',
            textDecoration: 'none',
          }}
          to="/"
        >
          Rep.ly
        </Link>
        <LinkMutedRouter
          to="/about"
          style={{ marginLeft: 'auto', boxShadow: 'none' }}
        >
          About Rep.ly
        </LinkMutedRouter>
      </Header>
      {state.featured.length > 0 && (
        <>
          <FeaturedPostTitle>
            <svg
              style={{ marginRight: 8 }}
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.06215 1.5345C6.38431 0.663886 7.61569 0.663887 7.93785 1.53451L9.00115 4.40803C9.10244 4.68175 9.31825 4.89756 9.59197 4.99885L12.4655 6.06215C13.3361 6.38431 13.3361 7.61569 12.4655 7.93785L9.59197 9.00115C9.31825 9.10244 9.10244 9.31825 9.00115 9.59197L7.93785 12.4655C7.61569 13.3361 6.38431 13.3361 6.06215 12.4655L4.99885 9.59197C4.89756 9.31825 4.68175 9.10244 4.40803 9.00115L1.5345 7.93785C0.663886 7.61569 0.663887 6.38431 1.53451 6.06215L4.40803 4.99885C4.68175 4.89756 4.89756 4.68175 4.99885 4.40803L6.06215 1.5345Z"
                fill="#000"
              />
            </svg>
            {/* <img
              src="icons/star.svg"
              alt="Featured Reply"
              style={{ marginRight: 8 }}
            /> */}
            Featured Reply
          </FeaturedPostTitle>
          <FeaturedPost to={`/answer/${state.featured[featIndex]._id}`}>
            <QuestionPure>{state.featured[featIndex].question}</QuestionPure>
            <AnsweredByPure
              to={`/answer/${state.featured[featIndex]._id}`}
              imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${state.featured[featIndex].to.username}/${state.featured[featIndex].to.s3AvatarKey}.jpg`}
              displayName={state.featured[featIndex].to.displayName}
            />
          </FeaturedPost>
        </>
      )}
      <Tabs>
        <TabList>
          <Tab>Latest Answers</Tab>
          {state.user && <Tab>For You</Tab>}
          <SearchContainer>
            <SearchComponent />
          </SearchContainer>
        </TabList>

        <TabPanel>
          <Timeline posts={state.recent} style={{ paddingLeft: 0 }} />
        </TabPanel>
        {state.user && (
          <TabPanel>
            <Timeline posts={state.forYou} style={{ paddingLeft: 0 }} />
          </TabPanel>
        )}
        <TabPanel>
          {state.searchResults.length === 0 &&
          !state.searchLoading &&
          state.searchQuery !== '' ? (
            <div style={{ textAlign: 'center' }}>
              <QuestionPure>No people found.</QuestionPure>
              <Answer>We couldn't find any results for that search.</Answer>
            </div>
          ) : null}
          <SearchResultComponent />
        </TabPanel>
      </Tabs>
    </Container>
  );
}
