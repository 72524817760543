import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(motion.a)`
  cursor: pointer;
  color: #111924;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 1px 0 rgba(17, 25, 36, 0.2);
  &:hover {
    box-shadow: 0 1px 0 rgba(17, 25, 36, 1);
  }
`;

const LinkMuted = styled(motion.a)`
  cursor: pointer;
  color: rgba(17, 25, 36, 0.5);
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 1px 0 rgba(17, 25, 36, 0.2);
  &:hover {
    color: rgba(17, 25, 36, 1);
    box-shadow: 0 1px 0 rgba(17, 25, 36, 1);
  }
`;

const LinkRouter = styled(RouterLink)`
  cursor: pointer;
  color: #111924;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 1px 0 rgba(17, 25, 36, 0.2);
  &:hover {
    box-shadow: 0 1px 0 rgba(17, 25, 36, 1);
  }
`;

const LinkMutedRouter = styled(RouterLink)`
  cursor: pointer;
  color: rgba(17, 25, 36, 0.5);
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 1px 0 rgba(17, 25, 36, 0.2);
  &:hover {
    color: rgba(17, 25, 36, 1);
    box-shadow: 0 1px 0 rgba(17, 25, 36, 1);
  }
`;

export { Link, LinkMuted, LinkRouter, LinkMutedRouter };
