import ky from 'ky';
import { selector } from 'recoil';

const currentUserQuery = selector({
  key: 'CurrentUser',
  get: async ({ get }) => {
    try {
      const response = await ky.get(`/auth/twitter/info`).json();
      return response;
    } catch (e) {
      return undefined;
    }
  },
});

export { currentUserQuery };
