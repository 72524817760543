import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => (props.value ? 'black' : '#ccc')};
  transition: background-color 0.2s;
  width: 28px;
  border-radius: 99px;
  padding: 2px;
  display: grid;
  cursor: pointer;
`;

const Dot = styled(motion.div)`
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 99px;
  justify-self: ${(props) => (props.value ? 'end' : 'start')};
`;

export default function Toggle({ value, setValue }) {
  return (
    <Wrapper onClick={() => setValue(!value)} value={value}>
      <Dot
        value={value}
        positionTransition={{
          damping: 5,
          stiffness: 50,
          type: 'tween',
          duration: 0.2,
        }}
      />
    </Wrapper>
  );
}
