import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { isMobile } from 'react-device-detect';

const Container = styled(motion.div)`
  position: absolute;
  top: -20px;
  left: 8px;
  white-space: nowrap;
  padding: 5px 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 6px;
  z-index: 2;
  pointer-events: none;
  transform-origin: bottom left;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);

  @media (max-width: 1280px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  line-height: 21px;
`;

export default function Tooltip({ label, top, left, children, onClose }) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('right');
  const ref = useRef(null);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        if (onClose) onClose();
      }, 200);
    }
  }, [visible]);

  useEffect(() => {
    if (ref.current) {
      const bbox = ref.current.getBoundingClientRect();
      if (bbox.right > 1200) {
        setPosition('left');
      }
    }
  }, [ref.current]);

  return (
    <Wrapper
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      <Container
        ref={ref}
        initial={false}
        animate={{
          opacity: visible ? 1 : 0,
          scale: visible ? 1 : 0.95,
        }}
        transition={{
          type: 'spring',
          mass: 0.1,
          damping: 20,
          stiffness: 200,
        }}
        style={{
          top: top ? top : -20,
          left: left ? left : 8,
        }}
      >
        {label}
      </Container>
    </Wrapper>
  );
}
