import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'react-tabs';
import { useDebounce } from 'react-use';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { useGlobalState } from '../../GlobalState';

export const SearchContainer = styled(Tab)`
  margin-left: auto;
  transform: translateY(-8px);
  list-style: none;
  outline: none;
`;

export const SearchInput = styled(motion.input)`
  position: relative;
  width: 180px;
  height: 32px;
  padding: 0 18px 0 34px;
  border-radius: 32px;
  border: 1px solid #ebebeb;
  outline: 0;
  font-size: 13px;
  font-weight: 500;
  transition: 0.1s ease border;
  box-sizing: border-box;
  box-shadow: none;
  -webkit-appearance: none;

  &::placeholder {
    color: #c1c1c1;
    font-weight: 500;
  }

  &:focus {
    border: 1px solid #000;
    & img {
      opacity: 0.5;
    }
  }

  @media (max-width: 1280px) {
    width: 140px;
  }
`;

export const SearchIcon = styled.img`
  position: absolute;
  top: 9px;
  left: 12px;
  pointer-events: none;
  user-select: none;
  z-index: 2;
  opacity: ${(props) => (props.focused ? 1 : 0.27)};
  transition: 0.1s ease opacity;
`;

export const SearchResult = styled(Link)`
  margin-bottom: 24px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const SearchResultAvatar = styled.img`
  width: 40px;
  height: 40px;
  user-select: none;
  border-radius: 40px;
`;

export const SearchResultTitle = styled.div`
  margin-left: 16px;
  font-weight: 600;
  color: #000;
  font-size: 14px;
  line-height: 24px;
`;

const SearchResultURL = styled.div`
  margin-left: 16px;
  color: #c1c1c1;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
`;

export function SearchResultComponent() {
  const [state, dispatch] = useGlobalState();
  return (
    <motion.div
      style={{ y: -32, display: state.searchLoading ? 'none' : 'block' }}
    >
      {state.searchResults.map((u) => {
        return (
          <SearchResult key={u._id} to={`/${u.username}`}>
            <SearchResultAvatar
              src={`${process.env.REACT_APP_ACCELERATOR_URL}/${u.username}/${u.s3AvatarKey}.jpg`}
              alt={u.displayName}
            />
            <div>
              <SearchResultTitle>{u.displayName}</SearchResultTitle>
              <SearchResultURL>rep.ly/{u.username}</SearchResultURL>
            </div>
          </SearchResult>
        );
      })}
    </motion.div>
  );
}

export function SearchComponent() {
  const [focused, setFocused] = useState(false);
  const [state, dispatch] = useGlobalState();
  const [val, setVal] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(val);
      dispatch({ type: 'SEARCH_USERS', payload: val });
    },
    200,
    [val]
  );

  return (
    <>
      <SearchIcon src="/icons/search.svg" alt="" focused={focused} />
      <SearchInput
        placeholder="Find People"
        value={val}
        animate={{
          width: focused ? 210 : 180,
        }}
        transition={{
          ease: [0.76, 0, 0.24, 1],
          duration: 0.2,
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={({ currentTarget }) => {
          if (!state.searchLoading) {
            dispatch({ type: 'SET_SEARCH_LOADING', payload: true });
          }
          setVal(currentTarget.value);
        }}
      />
    </>
  );
}
