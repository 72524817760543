import React, { useEffect, useState } from 'react';

import ky from 'ky';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import ProfileAnswers from './ProfileAnswers';
import AnswerForm from './AnswerForm';
import { LinkMuted, LinkMutedRouter } from './Link';
import { useGlobalState } from '../GlobalState';
import { isBrowser, isMobile } from 'react-device-detect';
import { Answer, Tabs, Tab } from './UI/Timeline';
import Timeline from './Timeline';

const QuestionContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const Info = styled(motion.div)`
  margin-right: 72px;
  margin-left: -100px;
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  line-height: 150%;

  @media (max-width: 1280px) {
    margin-left: 0;
    text-align: left;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const Content = styled(motion.div)`
  max-width: 600px;
  width: 100%;
`;

const Question = styled(motion.div)`
  font-size: 16px;
  font-weight: bold;
  line-height: 143%;
`;

const EmptyContainer = styled(motion.div)`
  margin-top: 56px;
  margin-left: auto;
  text-align: center;
  max-width: 600px;
`;

export default function ProfileQuestions({ profile }) {
  const [state, dispatch] = useGlobalState();
  const [activeTab, setActiveTab] = useState('unanswered');

  useEffect(() => {
    if (profile && profile._id) dispatch({ type: 'LOAD_SELF' });
  }, [profile]);

  const yesFAQ = state.answers.filter((q) => q.isFAQ === true);

  return (
    <>
      <Tabs>
        <Tab
          active={activeTab === 'answered'}
          onClick={() => setActiveTab('answered')}
        >
          Answered{' '}
          {state.userQALoading ? '...' : `(${state.profileAnswers.length})`}
        </Tab>
        <Tab
          active={activeTab === 'unanswered'}
          onClick={() => setActiveTab('unanswered')}
        >
          Unanswered{' '}
          {state.userQALoading ? '...' : `(${state.questions.length})`}
        </Tab>
        <Tab active={activeTab === 'faq'} onClick={() => setActiveTab('faq')}>
          FAQs {state.userQALoading ? '...' : `(${yesFAQ.length})`}
        </Tab>
      </Tabs>

      {state.questions.length === 0 && activeTab === 'unanswered' ? (
        <EmptyContainer>
          <Question>You have no unanswered questions.</Question>
          <Answer>Share your rep.ly page to receive new questions.</Answer>
        </EmptyContainer>
      ) : null}

      {state.profileAnswers.length === 0 && activeTab === 'answered' ? (
        <EmptyContainer>
          <Question>You have no answered questions.</Question>
          <Answer>Answer questions for them to show up here.</Answer>
        </EmptyContainer>
      ) : null}

      {yesFAQ.length === 0 && activeTab === 'faq' ? (
        <EmptyContainer>
          <Question>You have no FAQs.</Question>
          <Answer>Add some FAQs for them to show up here.</Answer>
        </EmptyContainer>
      ) : null}

      <ProfileAnswers
        profile={profile}
        profileAnswers={state.profileAnswers}
        visible={activeTab === 'answered'}
        empty={!state.userQALoading && state.profileAnswers.length === 0}
      />

      <ProfileAnswers
        profile={profile}
        profileAnswers={yesFAQ}
        type="faq"
        visible={activeTab === 'faq'}
        empty={!state.userQALoading && yesFAQ.length === 0}
      />

      <motion.div
        style={{ display: activeTab === 'unanswered' ? 'block' : 'none' }}
      >
        <Timeline posts={state.questions} personal={true} />
      </motion.div>
    </>
  );
}
