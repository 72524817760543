import React, { useEffect, useState } from 'react';

import ky from 'ky';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { isMobile, isBrowser } from 'react-device-detect';

import { useGlobalState } from '../GlobalState';
import Tooltip from './UI/Tooltip';
import Timeline from './Timeline';

const Container = styled(motion.div)`
  padding: 56px 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1280px) {
    padding: 24px 16px;
    align-items: flex-start;
  }
`;

const UserContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const UserInfo = styled(motion.div)`
  padding-left: 16px;
`;

const UserAvatar = styled(motion.img)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background: #ccc;
`;

const ViewFullPage = styled(RouterLink)`
  margin: 58px auto 73px 264px;
  font-size: 13px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: rgba(48, 48, 48, 0.3);
  text-decoration: none;

  & img {
    transition: 0.2s ease all;
  }

  &:hover {
    & img {
      transform: translateX(-4px);
    }
  }

  @media (max-width: 1280px) {
    margin: 32px 0 32px 0;
  }
`;

const GreenText = styled.span`
  color: #6ad668;
`;

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

export default function SingleAnswerPage() {
  const params = useParams();
  const [state, dispatch] = useGlobalState();
  const [profile, setProfile] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [copied, setCopied] = useState(false);

  async function getAnswer(_id) {
    const res = await ky.get(`/v1/answer/single/${_id}`).json();
    setProfile(res.to);
    setAnswer(res);
  }

  // Focus text area
  useEffect(() => {
    if (params._id) {
      getAnswer(params._id);
    }
  }, [params]);

  if (!profile || !answer) {
    return null;
  }

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
    >
      <Helmet>
        <title>{answer.question}</title>
        <meta name="og:description" content={truncate(answer.answer, 20)} />
      </Helmet>
      <UserContainer>
        <UserAvatar
          src={`${process.env.REACT_APP_ACCELERATOR_URL}/${profile.username}/${profile.s3AvatarKey}.jpg`}
          alt={profile.username}
        />
        <UserInfo>
          <div style={{ fontSize: 17, marginBottom: 8, fontWeight: 600 }}>
            {profile.displayName}
          </div>
          <a
            href={`https://twitter.com/${profile.username}`}
            rel="nofollow noopener"
          >
            <Tooltip
              label="This user has confirmed their identity through Twitter."
              left={8}
            >
              <img src="../icons/twitter.svg" />
            </Tooltip>
          </a>

          {isBrowser ? (
            <Tooltip
              label={
                copied ? (
                  <GreenText>Copied!</GreenText>
                ) : (
                  `Copy Page Link · rep.ly/${profile.username}`
                )
              }
              left={12}
              onClose={() => setCopied(false)}
            >
              <CopyToClipboard
                text={`https://rep.ly/${profile.username}`}
                style={{ marginLeft: 8, cursor: 'pointer' }}
                onCopy={() => setCopied(true)}
              >
                <img src="../icons/link.svg" />
              </CopyToClipboard>
            </Tooltip>
          ) : null}
        </UserInfo>
      </UserContainer>

      <ViewFullPage to={`/${profile.username}`}>
        <img
          src="../icons/view-full.svg"
          alt="View Full Page"
          style={{ marginRight: 8 }}
        />
        View Full Page
      </ViewFullPage>

      <Timeline posts={[answer]} personal={true} />
    </Container>
  );
}
