import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Button } from '../Button';
import { useGlobalState } from '../../GlobalState';
import { IsAuthed, NotAuthed } from '../helpers/Auth';
import { LinkMuted } from '../Link';

const Container = styled(motion.div)`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  width: 100%;
  height: 52px;
  background: #fff;
  box-shadow: 0px 1px 0px #f2f2f2;
  z-index: 2;

  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  margin-right: auto;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

const SidebarNavItemLink = styled(Link)`
  width: 40px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  transition: 0.15s ease all;
  & img {
    user-select: none;
  }
`;

function SidebarNavItem({ children, to, active, ...rest }) {
  const location = useLocation();
  return (
    <motion.div {...rest}>
      <SidebarNavItemLink to={to} active={location.pathname === to ? 1 : 0}>
        {children}
      </SidebarNavItemLink>
    </motion.div>
  );
}

export default function MobileNav() {
  const [state, dispatch] = useGlobalState();
  const history = useHistory();

  return (
    <Container>
      <IsAuthed>
        <Avatar
          onClick={() => history.push(`/${state.user.username}`)}
          src={
            state.user
              ? state.user.twitterProfileImageUrl.replace('_normal', '')
              : null
          }
          alt={state.user ? state.user.displayName : null}
        />
        <SidebarNavItem to="/" style={{ marginLeft: 'auto' }}>
          <img src="/icons/home.svg" alt="Home" />
        </SidebarNavItem>
        <SidebarNavItem to={state.user ? `/${state.user.username}` : ''}>
          <img src="/icons/profile.svg" alt="Your Profile" />
        </SidebarNavItem>
        <LinkMuted href={`/auth/twitter/logout`} style={{ marginLeft: 12 }}>
          Logout
        </LinkMuted>
      </IsAuthed>
      <NotAuthed>
        <Logo to="/">Rep.ly</Logo>
        <Button
          onClick={() => dispatch({ type: 'SHOW_TWITTER_MODAL' })}
          noShadow
        >
          Get Started
        </Button>
      </NotAuthed>
    </Container>
  );
}
