import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { LinkMuted } from '../old_components/Link';

const Container = styled.div`
  padding: 56px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  padding-right: 111px;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 1280px) {
    padding: 48px 16px;
  }
`;
const Desc = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
  margin-bottom: 56px;
  font-size: 13px;
  max-width: 604px;
  width: 100%;
  line-height: 143.2%;
`;

const Question = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 143%;
`;
const Answer = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 39px;
  font-size: 16px;
  font-family: Georgia, serif;
  line-height: 143%;
  white-space: pre-wrap;

  & a {
    color: #000;
    font-size: 16px;
    font-family: Georgia, serif;
  }
`;

export default function Landing() {
  const blocks = [
    {
      q: '1. Important information ',
      a: `What are these Terms of Use?
These terms (“TOU”) are part of the legal documents that set out your access to your use of Rep.ly (the “Services”).

Why are these TOU important?
By using the Services, you will be bound by the terms and conditions of these TOU and to the Privacy Policy. Please read them as they create a legally binding relationship between you and Rep.ly (“Rep.ly”, “we”, “our” or “us”).

Who is Rep.ly?
Rep.ly is a product which has been created by Dip LLC, a Delaware limited liability company, so Rep.ly is subject to Delaware law.

What if I don’t accept these TOU?
If you don’t accept these TOU, you must stop using or accessing the Services.`,
    },
    {
      q: '2. Who can use Rep.ly? ',
      a: `How old do I need to be?
You must be at least 13 years old to use the Services.

What laws do I need to follow?
You are responsible for making sure you follow all laws and regulations in the country in which you live when you access and use the Services.

Do you do any background or criminal checks?
We do not conduct any background checks on users, criminal or otherwise, so you need to be careful when interacting with other people on Rep.ly.`,
    },
    {
      q: '3. Registering to use Rep.ly',
      a: `Do I need to register to use the Services?
Yes.`,
    },
    {
      q: '4. What are the rules of using Rep.ly?',
      a: `Harassment
We do not tolerate harassment or cyberbulling of any kind on Rep.ly. If your account is found to be in violation of this, we will remove it. We support all viewpoints but want to maintain a safe and kind platform.

Explicit Content
We do not allow sexual or adult content of any kind on Rep.ly. Accounts found to be in violation of this will be removed immediately.

Respect 
We ask that all users be respectful when asking and answering questions. Our aim is to create a safe space that is informative and enjoyable to use. That only happens when everyone respects each other.

Do you check my account and my posts?
We can check your account and block or respond to content that we decide is unacceptable.

Can you block any content I posted if I broke the rules?
We reserve the right, at any time and without prior notice, to remove or disable access to any content for any reason or no reason.`,
    },
    {
      q: '5. How can I deactivate my account?',
      a: 'You can email us at any time to deactivate your account. ',
    },
    {
      q: '6. Legal disclaimers, indemnities and liability.',
      a: (
        <>
          You acknowledge and agree that the Services are provided “as is” and
          “as available” and that your use of the Services shall be at your sole
          risk. To the fullest extent permitted by applicable law, Rep.ly, and
          its respective officers, directors, employees, agents, affiliates,
          subsidiaries, and licensors (“Rep.ly parties”) disclaim all
          warranties, express or implied, in connection with the Services and
          your use of them. To the fullest extent permitted by applicable law,
          the Rep.ly parties make no warranties or representations that we have
          the necessary skill to render the Services or that the Services have
          been and will be provided with due skill, care and diligence or about
          the accuracy or completeness of the Services’ content and assume no
          responsibility for any (i) errors, mistakes, or inaccuracies of
          content, (ii) personal injury or property damage, of any nature
          whatsoever, resulting from your access to and use of our service,
          (iii) any unauthorized access to or use of our servers and/or any and
          all personal information and/or financial information stored therein,
          (iv) any interruption or cessation of transmission to or from the
          Services, (v) any bugs, viruses, trojan horses, or the like which may
          be transmitted to or through the Services through the actions of any
          third party, (vi) any loss of your data or content from the Services
          and/or (vii) any errors or omissions in any content or for any loss or
          damage of any kind incurred as a result of the use of any content
          posted, emailed, transmitted, or otherwise made available via the
          Services. the Rep.ly parties do not warrant, endorse, guarantee, or
          assume responsibility for any product or service advertised or offered
          by a third party through the Services, and the Rep.ly parties will not
          be a party to or in any way be responsible for monitoring any
          transaction between you and third-party providers of products or
          Services.
          <br />
          <br />
          To the fullest extent permitted by applicable law, in no event shall
          the Rep.ly parties be liable to you for any direct, indirect,
          incidental, special, punitive, or consequential damages whatsoever
          resulting from any (i) errors, mistakes, or inaccuracies of content,
          (ii) personal injury or property damage, of any nature whatsoever,
          resulting from your access to and use of the Services, (iii) any
          unauthorized access to or use of our servers and/or any and all
          personal information stored therein, (iv) any interruption or
          cessation of transmission to or from our servers, (v) any bugs,
          viruses, trojan horses, or the like, which may be transmitted to or
          through the service by any third party, (vi) any loss of your data or
          content from the Services (vii) any errors or omissions in any content
          or for any loss or damage of any kind incurred as a result of your use
          of any content posted, transmitted, or otherwise made available via
          the Services, whether based on warranty, contract, tort, or any other
          legal theory, and whether or not the Rep.ly parties are advised of the
          possibility of such damages, and/or (viii) the disclosure of
          information pursuant to these terms of use or privacy policy. despite
          the above, our total liability to you for any cause of action you take
          against us will at all times be limited to one hundred US dollars
          ($100.00)
          <br />
          <br />
          You specifically acknowledge and agree that the Rep.ly parties are not
          publishers of user submissions or liable for the defamatory,
          offensive, or illegal conduct of any third party. to the fullest
          extent permitted by applicable law, the Rep.ly parties disclaim all
          liability for user submissions. You further acknowledge and agree that
          content you publish and / or transmit on or through the Services to
          other users or entities may be copied, re-used, or further disclosed
          by such other users or entities outside of the Rep.ly parties’ control
          and that the Rep.ly parties are not liable to you for any such use of
          content by others.
          <br />
          <br />
          You agree to indemnify, defend, and hold the Rep.ly Parties harmless
          from and against any third-party claims, damages (actual and/or
          consequential), actions, proceedings, demands, losses, liabilities,
          costs and expenses (including reasonable legal fees) suffered or
          reasonably incurred by us arising as a result of, or in connection
          with, (i) your access to and use of Rep.ly; (ii) your breach of the
          TOU, including, but not limited to, any infringement by you of the
          copyright or intellectual property rights of any third party; or (iii)
          any products or Services purchased or obtained by you in connection
          with the Services. Rep.ly retains the exclusive right to settle,
          compromise and pay, without your prior consent, any and all claims or
          causes of action which are brought against us. We reserve the right,
          at your expense, to assume the exclusive defence and control of any
          matter, for which you are required to indemnify us and you agree to
          cooperate with our defence of these claims. You agree not to settle
          any matter in which we are named as a defendant and/or for which you
          have indemnity obligations without our prior written consent. We will
          use reasonable efforts to notify you of any such claim, action or
          proceeding upon becoming aware of it.
        </>
      ),
    },
    {
      q: '7. What law governs our relationship?',
      a: (
        <>
          The laws of Delaware apply to these TOU, including any contractual or
          non-contractual matter or dispute arising out of or in connection with
          these TOU, your access to and use of the Services, and the
          relationship between us and you. The courts of Delaware have exclusive
          jurisdiction in connection with these TOU and all such matters and
          disputes arising out of or connected to the Services.
        </>
      ),
    },
    {
      q: '8. Entire Agreement and Waiver.',
      a: (
        <>
          These TOU, together with the Privacy Policy and Cookie Policy, shall
          constitute the entire agreement between you and us concerning the
          Services. If for any reason, any provision of these TOU is declared to
          be illegal, invalid, void or otherwise unenforceable by a competent
          court of any jurisdiction, then to the extent that term is illegal,
          invalid, void or unenforceable, it shall be severed and deleted from
          these TOU, and the remainder of these TOU shall survive, remain in
          full force and effect and continue to be binding and enforceable. No
          failure or delay by us in exercising any right, power or privilege
          under these TOU shall operate as a waiver of such right or acceptance
          of any variation of these TOU and nor shall any single or partial
          exercise by either party of any right, power or privilege preclude any
          further exercise of that right or the exercise of any other right,
          power or privilege.
        </>
      ),
    },
    {
      q: '9. No Third Party Rights.',
      a:
        'Nothing in these TOU shall confer or purport to confer any rights on any other third party.',
    },
    {
      q: '10. Linking and Framing.',
      a: (
        <>
          These TOU, together with the Privacy Policy and Cookie Policy, shall
          constitute the entire agreement between you and us concerning the
          Services. If for any reason, any provision of these TOU is declared to
          be illegal, invalid, void or otherwise unenforceable by a competent
          court of any jurisdiction, then to the extent that term is illegal,
          invalid, void or unenforceable, it shall be severed and deleted from
          these TOU, and the remainder of these TOU shall survive, remain in
          full force and effect and continue to be binding and enforceable. No
          failure or delay by us in exercising any right, power or privilege
          under these TOU shall operate as a waiver of such right or acceptance
          of any variation of these TOU and nor shall any single or partial
          exercise by either party of any right, power or privilege preclude any
          further exercise of that right or the exercise of any other right,
          power or privilege.
        </>
      ),
    },
    {
      q: '11. How can I contact Rep.ly if I have a question or need help?',
      a: (
        <>
          If you have any questions about these TOU, you can contact us by
          clicking <a href="mailto:hello@dip.org">here</a>.
        </>
      ),
    },
  ];
  return (
    <Container>
      <Helmet>
        <title>Terms</title>
        <meta name="og:description" content="Terms of Use for Rep.ly." />
      </Helmet>
      <Question>Terms of Use</Question>
      <Desc>
        Our Terms of Use.
        <br />
        Questions? <LinkMuted href="mailto:hello@dip.org">Contact Us</LinkMuted>
      </Desc>
      <Question style={{ marginBottom: 32 }}>
        Effective Date: 30 April 2020
      </Question>
      {blocks.map((b) => {
        return (
          <>
            <Question>{b.q}</Question>
            <Answer>{b.a}</Answer>
          </>
        );
      })}
    </Container>
  );
}
