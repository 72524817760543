import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Button } from './Button';
import Checkbox from './Checkbox';
import Composer from './Composer';

const QuestionItem = styled(motion.div)`
  margin-bottom: 48px;
`;

const QuestionControls = styled(motion.div)`
  display: grid;
  /* 4 items: add to FAQ, separator dot, character count, button */
  grid-template-columns: repeat(3, max-content) 1fr;
  align-items: center;
  grid-column-gap: 11px;

  span {
    color: #c4c4c4;
  }

  label {
    font-size: 13px;
  }

  input {
    margin-left: 0; /* so it sits flush left */
  }

  button {
    justify-self: end; /* to right align button */
  }
`;

const AnswerCharCount = styled(motion.div)`
  margin-right: auto;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
`;

export default function AnswerForm({
  initialValue = '',
  question,
  isFAQ = false,
  onSubmit,
  autoFocus = false,
  buttonLabel = 'Reply',
}) {
  const [val, setVal] = useState(initialValue);
  const [btnVisible, setBtnVisible] = useState(false);
  const [FAQcheckbox, setFAQcheckbox] = useState(isFAQ);

  const textareaRef = useRef(null);

  return (
    <QuestionItem>
      <Composer
        autoFocus={autoFocus}
        onFocus={() => setBtnVisible(true)}
        onBlur={() => {
          setTimeout(() => {
            const textareaFocused = document.activeElement.isSameNode(
              textareaRef.current
            );

            if (!textareaFocused) {
              setBtnVisible(false);
            }
          }, 100);
        }}
        value={val}
        onChange={(e) => setVal(e.target.value)}
        placeholder="Reply..."
        innerRef={(el) => (textareaRef.current = el)}
      />

      <QuestionControls
        animate={{
          opacity: btnVisible ? 1 : 0,
        }}
        transition={{
          type: 'spring',
          mass: 0.1,
          damping: 20,
          stiffness: 220,
        }}
      >
        <Checkbox
          id={'make_faq' + Math.random()}
          checked={FAQcheckbox}
          onChange={(e) => {
            setFAQcheckbox(e.target.checked);
            textareaRef.current.focus();
          }}
          label="Add to FAQs"
        />

        <span>·</span>

        <AnswerCharCount
          initial={false}
          animate={{
            opacity: btnVisible ? (val.length === 0 ? 0.4 : 1) : 0,
          }}
          transition={{
            type: 'spring',
            mass: 0.1,
            damping: 20,
            stiffness: 220,
          }}
        >
          {val.length} / 5000
        </AnswerCharCount>
        <Button
          onClick={() =>
            onSubmit({ _id: question._id, answer: val, isFAQ: FAQcheckbox })
          }
          initial={false}
          animate={{
            opacity: btnVisible
              ? val.trim() === '' || val.length > 5000
                ? 0.4
                : 1
              : 0,
          }}
          transition={{
            type: 'spring',
            mass: 0.1,
            damping: 20,
            stiffness: 220,
          }}
          disabled={val.trim() === '' || val.length > 5000}
        >
          {buttonLabel}
        </Button>
      </QuestionControls>
    </QuestionItem>
  );
}
