import React, { useEffect, useState, useRef } from 'react';

import ky from 'ky';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useParams, useLocation } from 'react-router-dom';
import { isMobile, isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import { useGlobalState } from '../GlobalState';
import ProfileQuestions from './ProfileQuestions';
import ProfileAnswers from './ProfileAnswers';
import { Tabs, Tab } from './UI/Timeline';
import { ProfileHeader } from './UI/Profile';

const Container = styled(motion.div)`
  padding: 56px 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1280px) {
    padding: 32px 16px;
  }
`;

const Content = styled(motion.div)`
  margin-top: 48px;
  width: 100%;
`;

export default function ProfilePage() {
  const params = useParams();
  const location = useLocation();
  const activeTabFromURL = () =>
    location.pathname.endsWith('/faq') ? 'faq' : 'answers';
  const [activeTab, setActiveTab] = useState(activeTabFromURL());

  useEffect(() => {
    setActiveTab(activeTabFromURL());
  }, [location]);

  const [state, dispatch] = useGlobalState();
  let profile = state.profile;

  // Load profile
  useEffect(() => {
    if (params.username) {
      dispatch({ type: 'LOAD_PROFILE', payload: params.username });
    }
  }, [params]);

  if (!profile) {
    return null;
  }

  const yesFAQ = state.profileAnswers.filter((q) => q.isFAQ === true);

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.2,
      }}
    >
      <Helmet>
        <title>{profile.displayName}</title>
        <meta
          name="og:description"
          content={profile.displayName + ` is answering questions on Rep.ly.`}
        />
        <meta
          name="twitter:card"
          content={profile.displayName + ` is answering questions on Rep.ly.`}
        />
        <meta name="twitter:title" content={profile.displayName} />
        <meta
          name="twitter:description"
          content={profile.displayName + ` is answering questions on Rep.ly.`}
        />
      </Helmet>

      <ProfileHeader />

      <Content>
        {state.user && state.user.username === params.username ? (
          <ProfileQuestions profile={profile} />
        ) : (
          <>
            {/* Only show tabs if user has FAQ questions */}
            {yesFAQ.length > 0 && (
              <Tabs>
                <Tab
                  active={activeTab === 'answers'}
                  onClick={() => {
                    setActiveTab('answers');
                    // Using raw History API because it doesn't cause a re-render
                    // of the page, it just changes the URL silently.
                    window.history.pushState({}, null, `/${params.username}`);
                  }}
                >
                  Latest Answers
                </Tab>
                <Tab
                  active={activeTab === 'faq'}
                  onClick={() => {
                    setActiveTab('faq');
                    window.history.pushState(
                      {},
                      null,
                      `/${params.username}/faq`
                    );
                  }}
                >
                  FAQs
                </Tab>
              </Tabs>
            )}

            {activeTab === 'answers' && (
              <ProfileAnswers
                profile={profile}
                profileAnswers={state.profileAnswers}
                visible={true}
                empty={
                  !state.profileLoading && state.profileAnswers.length === 0
                }
              />
            )}

            {activeTab === 'faq' && (
              <ProfileAnswers
                profile={profile}
                profileAnswers={yesFAQ}
                type="faq"
                visible={true}
                empty={!state.profileLoading && yesFAQ.length === 0}
              />
            )}
          </>
        )}
      </Content>
    </Container>
  );
}
