import React from 'react';
import { useGlobalState } from '../../GlobalState';

export function IsAuthed({ children }) {
  const [state] = useGlobalState();
  if (state.userInitialLoad && state.user) {
    return <>{children}</>;
  } else {
    return null;
  }
}

export function NotAuthed({ children }) {
  const [state] = useGlobalState();
  if (state.userInitialLoad && !state.user) {
    return <>{children}</>;
  } else {
    return null;
  }
}
