import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ky from 'ky';
import { motion, AnimatePresence } from 'framer-motion';
import { isBrowser } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { useGlobalState } from '../../GlobalState';
import Settings from '../Settings';
import Tooltip from './Tooltip';
import Checkbox from '../Checkbox';
import { Button } from '../Button';
import { Link } from '../Link';
import Composer from '../Composer';

const ProfileHeaderContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }
`;

const UserAvatar = styled(motion.img)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background: #ccc;
`;

const UserInfo = styled(motion.div)`
  padding-left: 16px;
`;

const GreenText = styled.span`
  color: #6ad668;
`;

const UserAsk = styled(motion.div)`
  margin-left: auto;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  @media (max-width: 1280px) {
    margin-top: 16px;
    width: 100%;

    & button {
      width: 100%;
    }
  }
`;

const AskContainerBottom = styled.div`
  height: var(--bottom);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const AskContainerBase = styled(motion.div)`
  --top: 48px;
  --bottom: 48px;
  width: 100%;
  max-width: 600px;

  @media (max-width: 1280px) {
    --top: 32px;
    --bottom: 32px;
  }

  /* Setting these minimum heights makes the animations smooth. */
  textarea {
    min-height: 20px;
  }

  .rta__textarea {
    min-height: 34px;
  }
`;

const AskContainer = ({ children }) => (
  <AskContainerBase
    initial="closed"
    animate="open"
    exit="closed"
    variants={{
      open: {
        opacity: 1,
        height: 'auto',
        marginTop: 'var(--top)',
      },
      closed: {
        opacity: 0,
        height: 0,
        marginTop: 0,
      },
    }}
    transition={{
      type: 'spring',
      mass: 0.1,
      damping: 20,
      stiffness: 220,
    }}
  >
    {children}
  </AskContainerBase>
);

const AskTextarea = styled(TextareaAutosize)`
  margin-bottom: 14px;
  outline: none;
  width: 100%;
  max-width: 624px;
  height: 200px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  resize: none;
  border: 0;
  padding: 0;
  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

const AskControls = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  grid-row-gap: 10px;

  > div:first-child {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
  }

  .spacer {
    display: none;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;

    > div:last-child {
      display: grid;
      column-span: all;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
    }

    .spacer {
      display: block;
    }
  }
`;

const AskControlsMobile = styled.div``;

const AskCharCount = styled(motion.div)`
  color: rgba(0, 0, 0, 0.5);
`;

const AskControlSep = styled(motion.div)`
  margin: 0 12px;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #c4c4c4;
`;

export const ProfileHeader = () => {
  const [state, dispatch] = useGlobalState();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    setProfile(state.profile);
  }, [state]);

  const params = useParams();

  const [copied, setCopied] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const textareaRef = useRef(null);

  const [formOpen, setFormOpen] = useState(false);
  const [askAnon, setAskAnon] = useState(false);
  const [questionValue, setQuestionValue] = useState('');

  async function handleQuestionSubmit() {
    try {
      setFormOpen(false);
      dispatch({ type: 'SET_TOAST', payload: 'Question Submitted' });
      dispatch({ type: 'SHOW_TOAST' });
      await ky.post(`/v1/question`, {
        json: {
          question: questionValue,
          to: profile._id,
          anonymous: askAnon,
        },
      });
      if (params.username === state.user.username) {
        dispatch({ type: 'LOAD_SELF' });
      } else {
        dispatch({ type: 'RELOAD_PROFILE', payload: params.username });
      }

      setQuestionValue('');
    } catch (e) {
      console.error(e);
    }
  }

  const [FAQFormOpen, setFAQFormOpen] = useState(false);
  const [FAQAnswerValue, setFAQAnswerValue] = useState('');
  const [FAQLastTouchedInput, setFAQLastTouchedInput] = useState('question'); // enum - 'question' or 'answer'

  // Check whether we should block anonymous questions
  const blockAnonymous = state.profile?.allowAnonymousQuestions === false;

  const handleFAQSubmit = async () => {
    setFAQFormOpen(false);
    setQuestionValue('');
    setFAQAnswerValue('');

    dispatch({ type: 'SET_TOAST', payload: 'FAQ Added' });
    dispatch({ type: 'SHOW_TOAST' });

    await ky.post('/v1/question/faq', {
      json: {
        question: questionValue,
        answer: FAQAnswerValue,
      },
    });

    dispatch({ type: 'LOAD_SELF' });
  };

  // Focus text area
  useEffect(() => {
    if (formOpen && textareaRef.current) {
      textareaRef.current._ref.focus();
    }
  }, [formOpen, textareaRef]);

  return (
    <>
      <ProfileHeaderContainer>
        <UserAvatar
          src={`${process.env.REACT_APP_ACCELERATOR_URL}/${profile.username}/${profile.s3AvatarKey}.jpg`}
          alt={profile.username}
        />
        <UserInfo>
          <div
            style={{
              fontSize: 17,
              marginBottom: 8,
              fontWeight: 600,
              maxWidth: 350,
            }}
          >
            {profile.displayName}
          </div>
          <a
            href={`https://twitter.com/${profile.twitterUsername}`}
            rel="nofollow noopener"
          >
            <Tooltip
              label="This user has confirmed their identity through Twitter."
              left={8}
            >
              <img src="icons/twitter.svg" />
            </Tooltip>
          </a>
          {isBrowser ? (
            <Tooltip
              label={
                copied ? (
                  <GreenText>Copied!</GreenText>
                ) : (
                  `Copy Page Link · rep.ly/${profile.username}`
                )
              }
              left={12}
              onClose={() => setCopied(false)}
            >
              <CopyToClipboard
                text={`https://rep.ly/${profile.username}`}
                style={{ marginLeft: 8, cursor: 'pointer' }}
                onCopy={() => setCopied(true)}
              >
                <img src="/icons/link.svg" />
              </CopyToClipboard>
            </Tooltip>
          ) : null}

          {state.user && params.username === state.user.username && (
            <>
              <img
                src="icons/settings.svg"
                style={{
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
                onClick={() => setSettingsOpen(true)}
              />

              <Settings
                visible={settingsOpen}
                close={() => setSettingsOpen(false)}
              />
            </>
          )}
        </UserInfo>
        <UserAsk
          visible={state.user && params.username !== state.user.username}
        >
          <Button disabled={formOpen} onClick={() => setFormOpen(true)}>
            Ask Me Anything
          </Button>
        </UserAsk>
        <UserAsk
          visible={state.user && params.username === state.user.username}
        >
          <Button disabled={FAQFormOpen} onClick={() => setFAQFormOpen(true)}>
            Add FAQ
          </Button>
        </UserAsk>
        <UserAsk visible={!state.user}>
          <Button onClick={() => dispatch({ type: 'SHOW_TWITTER_MODAL' })}>
            Ask Me Anything
          </Button>
        </UserAsk>
      </ProfileHeaderContainer>

      <AnimatePresence>
        {formOpen && (
          <AskContainer>
            <AskTextarea
              placeholder="Ask something..."
              value={questionValue}
              onChange={(e) => setQuestionValue(e.target.value)}
              maxLength={280}
              ref={textareaRef}
            ></AskTextarea>
            <AskControls>
              <div>
                <Checkbox
                  id="ask_anon"
                  checked={askAnon}
                  onChange={(e) => setAskAnon(e.target.checked)}
                  hideInput={blockAnonymous}
                  label={
                    blockAnonymous ? 'Anonymous Questions Disabled' : undefined
                  }
                />
                <AskControlSep />
                <AskCharCount>{questionValue.length} / 280</AskCharCount>
              </div>

              <div>
                <Link
                  onClick={() => {
                    setQuestionValue('');
                    setFormOpen(false);
                  }}
                  style={{ marginRight: 24, marginLeft: 'auto' }}
                >
                  Cancel
                </Link>
                <div className="spacer" />
                <Button
                  disabled={questionValue.length === 0}
                  onClick={handleQuestionSubmit}
                >
                  Submit
                </Button>
              </div>
            </AskControls>
            <AskContainerBottom />
          </AskContainer>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {FAQFormOpen && state.user && params.username === state.user.username && (
          <AskContainer>
            <AskTextarea
              autoFocus={true}
              placeholder="Type your FAQ..."
              value={questionValue}
              onChange={(e) => setQuestionValue(e.target.value)}
              maxLength={280}
              ref={textareaRef}
              onFocus={() => setFAQLastTouchedInput('question')}
            ></AskTextarea>

            <Composer
              placeholder="Answer..."
              value={FAQAnswerValue}
              onChange={(e) => setFAQAnswerValue(e.target.value)}
              onFocus={() => setFAQLastTouchedInput('answer')}
            />

            <AskControls>
              <AskCharCount>
                {
                  (FAQLastTouchedInput === 'answer'
                    ? FAQAnswerValue
                    : questionValue
                  ).trim().length
                }{' '}
                / {FAQLastTouchedInput === 'answer' ? 5000 : 280}
              </AskCharCount>
              <div>
                <Link
                  onClick={() => {
                    setQuestionValue('');
                    setFAQAnswerValue('');
                    setFAQFormOpen(false);
                  }}
                  style={{ marginRight: 24, marginLeft: 'auto' }}
                >
                  Cancel
                </Link>
                <div className="spacer" />
                <Button
                  disabled={
                    questionValue.length === 0 || FAQAnswerValue.length === 0
                  }
                  onClick={handleFAQSubmit}
                >
                  Submit
                </Button>
              </div>
            </AskControls>
            <AskContainerBottom />
          </AskContainer>
        )}
      </AnimatePresence>
    </>
  );
};
