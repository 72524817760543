import React, { useState } from 'react';

import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import Linkify from 'react-linkify';
import { isMobile, isBrowser } from 'react-device-detect';
import ky from 'ky';

import { useGlobalState } from '../GlobalState';
import { LinkMuted, LinkMutedRouter } from './Link';
import { Answer, FAQBadge } from './UI/Timeline';
import Tooltip from './UI/Tooltip';
import Timeline from './Timeline';

const Info = styled(motion.div)`
  margin-right: 72px;
  margin-top: 3px;

  color: rgba(0, 0, 0, 0.5);
  text-align: right;
  line-height: 150%;

  @media (max-width: 1280px) {
    text-align: left;
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

const Content = styled(motion.div)`
  max-width: 600px;
  width: 100%;
`;

const Question = styled(RouterLink)`
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: bold;
  line-height: 143%;
  text-decoration: none;
`;

const EmptyContainer = styled(motion.div)`
  margin-top: 48px;
  margin-left: auto;
  text-align: center;
  max-width: 600px;
`;

const TimeAgoLink = styled(motion.div)`
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;

  @media (max-width: 1280px) {
    display: inline-block;
  }
`;

export default function ProfileAnswers({
  profile,
  profileAnswers = [],
  visible,
  type = '',
  empty = false,
}) {
  const [state, dispatch] = useGlobalState();

  if (empty) {
    return (
      <EmptyContainer
        style={{
          display:
            visible && (!state.user || state.user.username !== profile.username)
              ? 'block'
              : 'none',
        }}
      >
        <Question to={'#'}>Nothing to see here.</Question>
        <Answer>
          {type === 'faq'
            ? `${profile.displayName} hasn't added any FAQs.`
            : `${profile.displayName} hasn't answered any questions yet.`}
        </Answer>
      </EmptyContainer>
    );
  }

  return (
    <motion.div style={{ display: visible ? 'block' : 'none' }}>
      <Timeline posts={profileAnswers} personal={true} />
    </motion.div>
  );
}
