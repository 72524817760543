export function truncate(text, length) {
  const textWithoutMarkdown = text
    .replace(/\*(.+?)\*/g, '$1')
    .replace(/_(.+?)_/g, '$1');

  const truncatedText =
    textWithoutMarkdown.length > length
      ? textWithoutMarkdown.substr(0, length - 1) + '...'
      : textWithoutMarkdown;

  return truncatedText;
}

export function formatDate(date) {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(new Date(date));
}
