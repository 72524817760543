import React from 'react';
import styled from 'styled-components';
import { LinkMuted } from '../old_components/Link';
import { Helmet } from 'react-helmet';

const Container = styled.div`
  padding: 56px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  padding-right: 111px;
  align-items: flex-end;
  justify-content: flex-start;
  @media (max-width: 1280px) {
    padding: 48px 16px;
  }
`;
const Desc = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
  margin-bottom: 56px;
  font-size: 13px;
  max-width: 604px;
  width: 100%;
  line-height: 143.2%;
`;

const Question = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 143%;
`;
const Answer = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 39px;
  font-size: 16px;
  font-family: Georgia, serif;
  line-height: 143%;
`;

export default function Landing() {
  const blocks = [
    {
      q: 'How do I create a Rep.ly page?',
      a:
        'You can create a Rep.ly page in 5 seconds by signing up with Twitter.',
    },
    {
      q: 'Why do I need a Twitter account to sign up?',
      a:
        'We use Twitter to keep things simple and to make sure users are who they say they are. If someone signs up with Twitter, their rep.ly/username will always match their Twitter username. This way, you know someone is legitimate.',
    },
    {
      q: 'Will Rep.ly share anything to Twitter?',
      a:
        'We will never share anything to your Twitter without your express permission.',
    },
    {
      q: 'Can I change my rep.ly/username?',
      a:
        'Your rep.ly/username is based on your Twitter @username. If you change your Twitter username, then log back in to Rep.ly, your rep.ly/username will update.',
    },
    {
      q: 'Can questions be asked anonymously?',
      a:
        'To ask questions, you must create a Rep.ly account. After that, questions can be asked anonymously.',
    },
    {
      q: 'What if I get a question I don’t like?',
      a:
        'If you receive a question you don’t like, you can either delete it or report it. We take reports seriously and will remove accounts that are harmful to others.',
    },
    {
      q: 'Is there a limit to how long questions can be?',
      a:
        'Yes. Questions can only be 280 characters long. This is to prevent spam and keep things simple.',
    },
    {
      q: 'Can I delete a question I’ve already answered?',
      a:
        'Yes. If you’d like to delete a question you’ve already answered, you can do this on the left of the question by pressing ‘Delete’.',
    },
    {
      q: 'Can other people see the questions I haven’t answered yet?',
      a: 'No. Only you can see the questions you haven’t answered.',
    },
    {
      q: 'How do I delete my rep.ly page?',
      a:
        'Please contact us and we can assist you with deleting your rep.ly page.',
    },
    {
      q: 'Is there a limit to how many questions I can ask or receive?',
      a: 'No.',
    },
    {
      q: 'Are there notifications for new questions or answers?',
      a: 'Not yet, but it’s on the roadmap.',
    },
  ];
  return (
    <Container>
      <Helmet>
        <title>FAQs</title>
        <meta
          name="og:description"
          content="Frequently asked questions about Rep.ly."
        />
      </Helmet>
      <Question>FAQs</Question>
      <Desc>
        Frequently asked questions about Rep.ly.
        <br />
        Other questions?{' '}
        <LinkMuted href="mailto:hello@dip.org">Contact Us</LinkMuted>
      </Desc>
      {blocks.map((b) => {
        return (
          <>
            <Question>{b.q}</Question>
            <Answer>{b.a}</Answer>
          </>
        );
      })}
    </Container>
  );
}
