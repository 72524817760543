import React, { useState } from 'react';
import ky from 'ky';
import styled from 'styled-components';

import { useGlobalState } from '../GlobalState';
import { formatDate } from './helpers/utils';
import {
  Post,
  PostSidebar,
  PostInfo,
  Question,
  Answer,
  AnsweredBy,
  AnsweredYour,
} from './UI/Timeline';
import { LinkMuted } from './Link';
import AnswerForm from './AnswerForm';

// Overrides styles from AnswerForm in order to ease the transition from viewing to editing.
const AnswerFormWrapper = styled.div`
  textarea {
    padding-top: 0;
  }

  > div {
    margin-bottom: 0;
  }
`;

const TimelineWrapper = styled.div`
  align-self: flex-start;
  padding-left: 264px;
  width: 100%;

  @media (max-width: 1280px) {
    padding-left: 0;
  }
`;

const Timeline = ({ posts, personal = false, style }) => {
  /* `personal` prop means that this timeline contains a single user's posts. 
      This means that bylines aren't shown, delete/edit controls are shown (if appropriate), etc. */

  const [state, dispatch] = useGlobalState();

  /* Actions */

  const deletePost = (id) => {
    dispatch({ type: 'DELETE_QUESTION', payload: id });
  };

  // Keeps _id of post that is in editing mode currently
  const [inEditingMode, setInEditingMode] = useState(null);

  const toggleEdit = (id) => {
    if (inEditingMode === id) {
      setInEditingMode(null);
    } else {
      setInEditingMode(id);
    }
  };

  const editAnswer = async ({ id, answer, isFAQ, wasFAQ }) => {
    setInEditingMode(null);

    await ky.post('/v1/answer/edit', {
      json: {
        _id: id,
        answer,
        isFAQ,
      },
    });

    if (isFAQ === false && wasFAQ === true) {
      // FAQ status changed from yes to no
      dispatch({
        type: 'SET_TOAST',
        payload: 'FAQ Removed',
      });
      dispatch({ type: 'SHOW_TOAST' });
    }

    dispatch({ type: 'LOAD_SELF' });
    dispatch({ type: 'RELOAD_PROFILE' });
  };

  const handleAnswerSubmit = async ({ _id, answer, isFAQ }) => {
    dispatch({ type: 'SET_TOAST', payload: 'Answer Shared' });
    dispatch({ type: 'SHOW_TOAST' });
    await ky.post('/v1/answer', {
      json: {
        _id,
        answer,
        anonymous: false,
        isFAQ,
      },
    });

    dispatch({ type: 'LOAD_SELF' });
  };

  return (
    <TimelineWrapper style={style}>
      {posts.map((post) => {
        const date = formatDate(post.updatedAt);

        /*
         * If the post was from you, the byline should be "... answered your question".
         * Otherwise, we default to "Answered by ..."
         */
        const Byline =
          post.from._id === state.user?._id ? AnsweredYour : AnsweredBy;

        const postToId = post.to?._id || post.to;
        const showEditControls = personal && state.user?._id === postToId;
        const showFAQBadge = post.isFAQ && state.user?._id !== postToId;

        const hasAnswer = post.answer !== undefined;

        return (
          <Post key={post._id}>
            <PostSidebar>
              <PostInfo
                date={date}
                username={post.from.username}
                displayName={post.from.displayName}
                showFAQBadge={showFAQBadge}
              />

              {showEditControls && (
                <>
                  <LinkMuted onClick={() => deletePost(post._id)}>
                    Delete
                  </LinkMuted>

                  {hasAnswer && (
                    <>
                      <span
                        style={{
                          margin: '0 4px',
                          color: 'rgba(17, 25, 36, 0.5)',
                        }}
                      >
                        {' '}
                        ·{' '}
                      </span>

                      <LinkMuted onClick={() => toggleEdit(post._id)}>
                        {post._id === inEditingMode ? 'Cancel' : 'Edit'}
                      </LinkMuted>
                    </>
                  )}
                </>
              )}
            </PostSidebar>

            <Question to={hasAnswer ? `/answer/${post._id}` : undefined}>
              {post.question}
            </Question>

            {!hasAnswer ? (
              <Answer>
                <AnswerForm question={post} onSubmit={handleAnswerSubmit} />
              </Answer>
            ) : post._id === inEditingMode ? (
              <Answer>
                <AnswerFormWrapper>
                  <AnswerForm
                    autoFocus={true}
                    initialValue={post.answer}
                    isFAQ={post.isFAQ || false}
                    question={{}}
                    onSubmit={({ answer, isFAQ }) =>
                      editAnswer({
                        id: post._id,
                        answer,
                        isFAQ,
                        wasFAQ: post.isFAQ,
                      })
                    }
                    buttonLabel="Update Reply"
                  />
                </AnswerFormWrapper>
              </Answer>
            ) : (
              <Answer text={post.answer} edited={post.edited} data={post} />
            )}

            {!personal && (
              <Byline
                to={`/${post.to.username}`}
                imageUrl={`${process.env.REACT_APP_ACCELERATOR_URL}/${post.to.username}/${post.to.s3AvatarKey}.jpg`}
                displayName={post.to.displayName}
              />
            )}
          </Post>
        );
      })}
    </TimelineWrapper>
  );
};

export default Timeline;
