import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { LinkMuted } from '../old_components/Link';

const Container = styled.div`
  padding: 56px 72px 72px 72px;
  display: flex;
  flex-direction: column;
  padding-right: 111px;
  align-items: flex-end;
  justify-content: flex-start;

  @media (max-width: 1280px) {
    padding: 48px 16px;
  }
`;
const Desc = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
  margin-bottom: 56px;
  font-size: 13px;
  max-width: 604px;
  width: 100%;
  line-height: 143.2%;
`;

const Question = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 143%;
`;
const Answer = styled.div`
  max-width: 604px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 39px;
  font-size: 16px;
  font-family: Georgia, serif;
  line-height: 143%;
`;

export default function Landing() {
  const blocks = [
    {
      q: 'How do I create a Rep.ly page?',
      a:
        'You can create a Rep.ly page in 5 seconds by signing up with Twitter here.',
    },
    {
      q: 'Why do I need a Twitter account to sign up?',
      a:
        'We use Twitter to keep things simple and to make sure users are who they say they are. If someone signs up with Twitter, their rep.ly/username will always match their Twitter username. This way, you know someone is legitimate.',
    },
    {
      q: 'Will Rep.ly share anything to Twitter?',
      a:
        'We will never share anything to your Twitter without your express permission. We also cannot access your DMs.',
    },
    {
      q: 'Can I change my rep.ly/username?',
      a:
        'Your rep.ly/username is based on your Twitter @username. If you change your Twitter username, then log back in to Rep.ly, your rep.ly/username will update.',
    },
    {
      q: 'Can questions be asked anonymously?',
      a:
        'To ask questions, you must create a Rep.ly account. After that, questions can be asked anonymously.',
    },
    {
      q: 'What if I get a question I don’t like?',
      a:
        'If you receive a question you don’t like, you can either delete it or report it. We take reports seriously and will remove accounts that are harmful to others.',
    },
    {
      q: 'Is there a limit to how long questions can be?',
      a:
        'Yes. Questions can only be 280 characters long. This is to prevent spam and keep things simple.',
    },
    {
      q: 'Can I delete a question I’ve already answered?',
      a:
        'Yes. If you’d like to delete a question you’ve already answered, you can do this on the left of the question by pressing ‘Delete’.',
    },
    {
      q: 'Can other people see the questions I haven’t answered yet?',
      a: 'No. Only you can see the questions you haven’t answered.',
    },
    {
      q: 'How do I delete my rep.ly page?',
      a:
        'Please contact us and we can assist you with deleting your rep.ly page.',
    },
    {
      q: 'Is there a limit to how many questions I can ask or receive?',
      a: 'No.',
    },
    {
      q: 'Are there notifications for new questions or answers?',
      a: 'Not yet, but it’s on the roadmap.',
    },
  ];
  return (
    <Container>
      <Helmet>
        <title>Privacy</title>
        <meta name="og:description" content="Privacy Policy for Rep.ly." />
      </Helmet>
      <Question>Privacy Policy</Question>
      <Desc>
        Our privacy policy.
        <br />
        Questions? <LinkMuted href="mailto:hello@dip.org">Contact Us</LinkMuted>
      </Desc>
      <Question>Effective March 17, 2020 </Question>
      <Answer>
        Your privacy is important to us. It is our policy to respect your
        privacy regarding any information we may collect from you through our
        platform.
        <br />
        <br />
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.
        <br />
        <br />
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
        <br />
        <br />
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.
        <br />
        <br />
        Our app may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their
        respective privacy policies.
        <br />
        <br />
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.
        <br />
        <br />
        Your continued use of our website will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.
      </Answer>
    </Container>
  );
}
