import React, { useEffect } from 'react';

import ky from 'ky';

import { useGlobalState } from '../GlobalState';

export default function AuthObserver() {
  const [state, dispatch] = useGlobalState();

  async function checkAuth() {
    try {
      const res = await ky.get(`/auth/twitter/info`).json();
      dispatch({ type: 'SET_USER', payload: res });
    } catch (e) {
      console.error(e);
    }
    dispatch({ type: 'USER_INITIAL_LOAD' });
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return <></>;
}
