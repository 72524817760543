import React, { createContext, useContext, useReducer } from 'react';
import { useReducerAsync } from 'use-reducer-async';
import ky from 'ky';

const StateContext = createContext(null);

const initialState = {
  // Self
  user: undefined,
  userInitialLoad: false,
  userQALoading: false,
  questions: [],
  answers: [],

  // Other people
  profile: undefined,
  profileAnswers: [],
  profileLoading: true,

  // Sidebar
  sidebarUsers: [],
  twitterModalVisible: false,

  // Toast
  toastVisible: false,
  toastMessage: '',

  // Global
  recent: [],
  forYou: [],
  activity: [],
  activityLoading: true,
  featured: [],
  searchLoading: false,
  searchResults: [],
  searchQuery: '',
};

async function getQuestions() {
  return await ky.get(`/v1/question`).json();
}

async function getAnswers(_id) {
  return await ky.get(_id ? `/v1/answer/${_id}` : `/v1/answer`).json();
}

async function getAnswersByUsername(username) {
  return await ky
    .get(username ? `/v1/answer/by_username/${username}` : `/v1/answer`)
    .json();
}

async function getRecentGlobal() {
  return await ky.get('/v1/answer/recent').json();
}

async function getForYou() {
  return await ky.get('/v1/answer/for-you').json();
}

async function getActivity({ signal }) {
  return await ky.get('/v1/user/activity', { signal }).json();
}

async function getFeatured() {
  return await ky.get('/v1/answer/featured').json();
}

async function getProfile(username) {
  return await ky.get(`/v1/user/${username}`).json();
}

async function deleteQuestion(_id) {
  try {
    return await ky.delete('/v1/question', {
      json: {
        _id,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

async function getRecentUsers() {
  return await ky.get(`/v1/user/recent-users`).json();
}

async function searchUsers(query, signal) {
  return await ky.get(`/v1/user/search/${query}`, { signal }).json();
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'USER_INITIAL_LOAD':
      return { ...state, userInitialLoad: true };
    case 'SET_SELF_QUESTIONS':
      return { ...state, questions: action.payload };
    case 'SET_SELF_ANSWERS':
      return { ...state, answers: action.payload };
    case 'SET_PROFILE':
      return { ...state, profile: action.payload };
    case 'SET_PROFILE_ANSWERS':
      return { ...state, profileAnswers: action.payload };

    // Sidebar
    case 'SET_SIDEBAR_USERS':
      return { ...state, sidebarUsers: action.payload };

    // Twitter modal
    case 'SHOW_TWITTER_MODAL':
      return { ...state, twitterModalVisible: true };
    case 'HIDE_TWITTER_MODAL':
      return { ...state, twitterModalVisible: false };

    // Toast
    case 'SHOW_TOAST':
      return { ...state, toastVisible: true };
    case 'HIDE_TOAST':
      return { ...state, toastVisible: false };
    case 'SET_TOAST':
      return { ...state, toastMessage: action.payload };

    // Loading
    case 'LOADING_SELF_QA':
      return { ...state, userQALoading: true };
    case 'DONE_SELF_QA':
      return { ...state, userQALoading: false };

    case 'LOADING_PROFILE':
      return { ...state, profileLoading: true };
    case 'DONE_PROFILE':
      return { ...state, profileLoading: false };

    // Global
    case 'SET_RECENT_GLOBAL':
      return { ...state, recent: action.payload };
    case 'SET_FOR_YOU':
      return { ...state, forYou: action.payload };
    case 'SET_ACTIVITY':
      return { ...state, activity: action.payload, activityLoading: false };
    case 'SET_FEATURED':
      return { ...state, featured: action.payload };
    case 'SET_SEARCH_LOADING':
      return { ...state, searchLoading: action.payload };
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };
    case 'SET_SEARCH_RESULTS':
      return { ...state, searchResults: action.payload };
    default:
      return state;
  }
}

const asyncActionHandlers = {
  DELETE_QUESTION: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const state = getState();
      dispatch({
        type: 'SET_SELF_ANSWERS',
        payload: state.answers.filter((a) => a._id !== action.payload),
      });
      dispatch({
        type: 'SET_SELF_QUESTIONS',
        payload: state.questions.filter((a) => a._id !== action.payload),
      });
      dispatch({
        type: 'SET_PROFILE_ANSWERS',
        payload: state.profileAnswers.filter((a) => a._id !== action.payload),
      });

      dispatch({ type: 'SET_TOAST', payload: 'Question Deleted' });
      dispatch({ type: 'SHOW_TOAST' });

      // TODO: Handle errors
      await deleteQuestion(action.payload);
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_PROFILE: ({ dispatch, getState, signal }) => async (action) => {
    try {
      dispatch({ type: 'SET_PROFILE', payload: null });
      dispatch({ type: 'SET_PROFILE_ANSWERS', payload: [] });

      dispatch({ type: 'LOADING_PROFILE' });

      const profile = await getProfile(action.payload);

      dispatch({ type: 'SET_PROFILE', payload: profile.user });
      dispatch({ type: 'SET_PROFILE_ANSWERS', payload: profile.answers });
      dispatch({ type: 'DONE_PROFILE' });
    } catch (err) {
      console.error(err);
    }
  },
  RELOAD_PROFILE: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const state = getState();
      if (!state.profile) throw new Error('No user to reload');

      // dispatch({ type: 'LOADING_PROFILE' });
      const profileAnswers = await getAnswers(state.profile._id);

      dispatch({ type: 'SET_PROFILE_ANSWERS', payload: profileAnswers });
      // dispatch({ type: 'DONE_PROFILE' });
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_SELF: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const state = getState();
      if (state.user) {
        dispatch({ type: 'LOADING_SELF_QA' });
        const [questions, answers] = await Promise.all([
          getQuestions(),
          getAnswers(),
        ]);
        dispatch({ type: 'SET_SELF_QUESTIONS', payload: questions });
        dispatch({ type: 'SET_SELF_ANSWERS', payload: answers });
        dispatch({ type: 'DONE_SELF_QA' });
      }
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_SIDEBAR: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const users = await getRecentUsers();
      dispatch({ type: 'SET_SIDEBAR_USERS', payload: users });
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_RECENT: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const recent = await getRecentGlobal();
      dispatch({ type: 'SET_RECENT_GLOBAL', payload: recent });
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_FEATURED: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const recent = await getFeatured();
      dispatch({ type: 'SET_FEATURED', payload: recent });
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_FORYOU: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const recent = await getForYou();
      dispatch({ type: 'SET_FOR_YOU', payload: recent });
    } catch (err) {
      console.error(err);
    }
  },
  LOAD_ACTIVITY: ({ dispatch, getState, signal }) => async (action) => {
    try {
      const activity = await getActivity({ signal });
      dispatch({ type: 'SET_ACTIVITY', payload: activity });
    } catch (err) {
      console.error(err);
    }
  },
  SEARCH_USERS: ({ dispatch, getState, signal }) => async (action) => {
    try {
      dispatch({ type: 'SET_SEARCH_QUERY', payload: action.payload });
      if (action.payload) {
        const users = await searchUsers(action.payload, signal);
        dispatch({ type: 'SET_SEARCH_LOADING', payload: false });
        dispatch({ type: 'SET_SEARCH_RESULTS', payload: users });
      } else {
        dispatch({ type: 'SET_SEARCH_LOADING', payload: false });
        dispatch({ type: 'SET_SEARCH_RESULTS', payload: [] });
      }
    } catch (err) {
      console.error(err);
    }
  },
};

export function GlobalProvider({ children }) {
  return (
    <StateContext.Provider
      value={useReducerAsync(reducer, initialState, asyncActionHandlers)}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(StateContext);
}
