import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Button = styled(motion.button)`
  display: inline-flex;
  flex-direction: row;
  padding: 8px 16px;

  outline: 0 !important;

  color: #111924;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid rgba(172, 180, 188, 0.5);
  box-shadow: ${(props) =>
    props.noShadow
      ? 'box-shadow: 0px 1px 2px rgba(21, 50, 83, 0.06)'
      : '0px 1px 2px rgba(21, 50, 83, 0.1)'};
  border-radius: 6px;
  justify-content: center;

  transition: 0.1s ease all;
  box-sizing: border-box;

  &:not(:disabled):hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export { Button };
