import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import ky from 'ky';

import { useGlobalState } from '../GlobalState';
import Toggle from './Toggle';
import { Button } from './Button';
import { Link } from './Link';

const Modal = styled(motion.div)`
  width: 384px;
  padding: 24px;
  border-radius: 8px;
  background-color: white;
  opacity: 0;
  pointer-events: auto;

  @media (max-width: 416px) {
    width: 100%;
  }
`;

const Backdrop = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  opacity: 0;
`;

const ModalWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  pointer-events: none;
  padding: 16px;
`;

const Title = styled.h1`
  font-weight: 600;
  color: black;
  margin: 0px;
  margin-bottom: 26px;
  font-size: 16px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 16px;
  align-items: center;
  margin-bottom: 10px;
`;

const SettingName = styled.p`
  font-size: 14px;
  margin: 0px;
  line-height: 1.3;
`;

const SettingDescription = styled.p`
  font-size: 13px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px;
  max-width: calc(100% - 40px);
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 24px;
  justify-items: end;
  align-items: center;

  * {
    font-size: 14px;
  }
`;

export default function Settings({ visible, close }) {
  const [allowAnonymous, setAllowAnonymous] = useState(true);

  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    // When the settings modal is hidden or first loads, revert the state of the toggle to the saved state.

    const rawAllowAnonymousQuestions = state.user?.allowAnonymousQuestions; // May be `undefined` if never changed before
    // If rawAllowAnonymousQuestions is undefined, default to true.
    const allowAnonymousQuestions =
      rawAllowAnonymousQuestions === undefined
        ? true
        : rawAllowAnonymousQuestions;

    setAllowAnonymous(allowAnonymousQuestions);
  }, [visible, state]);

  const save = async () => {
    await ky.post('/v1/user/settings', {
      json: {
        allowAnonymousQuestions: allowAnonymous,
      },
    });

    // Reload state.user to pull in new changes
    const res = await ky.get(`/auth/twitter/info`).json();
    dispatch({ type: 'SET_USER', payload: res });
  };

  return (
    <AnimatePresence>
      {visible && (
        <>
          {/* Background behind settings modal */}
          <Backdrop
            onClick={close}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.2,
            }}
          />

          {/* Modal itself, centered within a full-screen div */}
          <ModalWrapper>
            <Modal
              style={{
                y: 10,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                y: 10,
                opacity: 0,
              }}
              transition={{
                ease: [0.25, 0.1, 0.25, 1.0],
                duration: 0.2,
                type: 'spring',
                mass: 0.1,
                damping: 20,
                stiffness: 220,
              }}
            >
              <Title>Settings</Title>

              <Row>
                <SettingName>Allow Anonymous Questions</SettingName>

                <Toggle value={allowAnonymous} setValue={setAllowAnonymous} />
              </Row>

              <SettingDescription>
                Turning this off will prevent people from asking you questions
                anonymously.
              </SettingDescription>

              <ButtonWrapper>
                <Link onClick={close}>Cancel</Link>
                <Button
                  onClick={() => {
                    save();
                    close();
                  }}
                >
                  Save
                </Button>
              </ButtonWrapper>
            </Modal>
          </ModalWrapper>
        </>
      )}
    </AnimatePresence>
  );
}
