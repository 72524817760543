import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid rgba(172, 180, 188, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(21, 50, 83, 0.05);
  appearance: none;
  position: relative;
  transition: background 0.15s, border-color 0.15s, scale 0.15s;
  margin: 0;
  margin-right: 3px;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :active {
    transform: scale(0.9);
  }

  :checked {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)),
      #ffffff;
    border-color: #acb4bc;
  }

  ::after {
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    content: '';
    background-image: url('/icons/checkmark.svg');
    background-size: 4px 4px;
    background-repeat: no-repeat;
    background-position: center;
    height: 8px;
    width: 8px;
    opacity: 0;
    scale: 0.5;
    transition: 0.1s;
  }

  :checked::after {
    opacity: 1;
    background-size: 8px 8px;
  }
`;

const Label = styled.label`
  margin-left: 8px;
  user-select: none;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  align-items: center;
`;

export default function Checkbox({
  id,
  label = 'Ask Anonymously',
  checked,
  onChange = () => {},
  hideInput = false,
}) {
  return (
    <Container>
      {!hideInput && (
        <Input type="checkbox" id={id} checked={checked} onChange={onChange} />
      )}
      <Label
        htmlFor={id}
        style={{
          marginLeft: hideInput ? 0 : undefined, // There's no reason to have margin-left if checkbox is hidden
        }}
      >
        {label}
      </Label>
    </Container>
  );
}
