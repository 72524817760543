import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

const TabListStyled = styled(TabList)`
  margin-top: 0;
  padding-left: 0;
  margin-bottom: 56px;
  display: flex;
  width: 100%;
  margin-left: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  @media (max-width: 1280px) {
    margin-bottom: 48px;
  }
`;

const TabItem = styled(Tab)`
  outline: none;
  cursor: pointer;
  margin-right: 24px;
  padding-bottom: 21px;
  list-style: none;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transform: translateY(1px);
  user-select: none;
  transition: 0.1s ease all;

  &.active {
    color: rgba(0, 0, 0, 1);
    border-bottom: 1px solid #000;
  }
`;

const TabsStyled = ({ children }) => (
  <Tabs selectedTabClassName="active">{children}</Tabs>
);

export {
  TabListStyled as TabList,
  TabItem as Tab,
  TabPanel,
  TabsStyled as Tabs,
};
