import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';

import { useGlobalState } from '../GlobalState';

import { Button } from './Button';
import { Link, LinkMuted, LinkMutedRouter, LinkRouter } from './Link';
import Tooltip from './UI/Tooltip';
import Activity from './Activity';

const Container = styled(motion.div)`
  top: 0;
  position: sticky;
  padding: 66px 72px;
  width: 30vw;
  height: 100vh;
  border-left: 1px solid #efefef;
`;

const Logo = styled(motion.div)`
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
`;

const Desc = styled(motion.div)`
  color: rgba(0, 0, 0, 0.5);
  line-height: 143.2%;
  margin-bottom: 16px;
`;

const AvatarContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(4, 40px);
  column-gap: 16px;
  row-gap: 16px;
`;

const Avatar = styled(motion.a)`
  cursor: pointer;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
  &:hover {
    opacity: 0.8;
  }
`;

const TopContent = styled(motion.div)`
  margin-bottom: 32px;
  display: flex;
`;

const LoggedInNav = styled.div`
  margin-bottom: 32px;
`;

const SelfAvatar = styled.img`
  cursor: pointer;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 40px;

  &:hover {
    opacity: 0.8;
  }
`;

const NavigationContainer = styled.div`
  margin-top: 48px;
  display: flex;
  align-items: center;
  & a {
    box-shadow: none !important;
  }
`;

const NavigationSep = styled.div`
  margin: 0 12px;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #c4c4c4;
`;

const TopRight = styled.div``;

function NoAuthContent() {
  const [state, dispatch] = useGlobalState();

  function handleCreate() {
    // window.open(`/auth/twitter`, '_self');
    dispatch({ type: 'SHOW_TWITTER_MODAL' });
  }
  return (
    <div style={{ marginBottom: 16 }}>
      <Desc>
        Create your own public Q&A page.
        <br />
        Answer questions from anyone, anytime.
      </Desc>
      <motion.div
        style={{
          marginTop: 27,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button onClick={handleCreate}>Create A Page</Button>
        <motion.div
          style={{
            marginLeft: 16,
            marginRight: 12,
            color: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          or
        </motion.div>
        <Link onClick={handleCreate}>Login</Link>
      </motion.div>
    </div>
  );
}

function AuthContent() {
  return (
    <>
      <Desc style={{ marginBottom: 0 }}>
        Not you? <LinkMuted href={`/auth/twitter/logout`}>Logout</LinkMuted>
      </Desc>
    </>
  );
}

const SidebarNavItemPure = styled(motion.div)`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.27)};
  transition: 0.15s ease all;
  user-select: none;
  & img {
    user-select: none;
    margin-right: 16px;
  }
`;

const SidebarNavItemLink = styled(RouterLink)`
  height: 32px;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  opacity: ${(props) => (props.active ? 1 : 0.27)};
  transition: 0.15s ease all;
  user-select: none;
  & img {
    user-select: none;
    margin-right: 16px;
  }
`;

function SidebarNavItem({ children, to, active, ...rest }) {
  const location = useLocation();
  return (
    <motion.div {...rest}>
      {to ? (
        <SidebarNavItemLink to={to} active={location.pathname === to ? 1 : 0}>
          {children}
        </SidebarNavItemLink>
      ) : (
        <SidebarNavItemPure active={active}>{children}</SidebarNavItemPure>
      )}
    </motion.div>
  );
}

export default function Sidebar() {
  const [state, dispatch] = useGlobalState();
  const history = useHistory();
  const location = useLocation();

  const [activityOpen, setActivityOpen] = useState(false);
  const activityRef = useRef(null);

  useEffect(() => {
    dispatch({ type: 'LOAD_SIDEBAR' });
  }, []);

  useEffect(() => {
    setActivityOpen(false);
  }, [location.pathname]);

  useClickAway(activityRef, (e) => {
    if (e.target.innerText !== 'Activity') setActivityOpen(false);
  });

  return (
    <Container
      initial={false}
      animate={{
        opacity: state.userInitialLoad && state.sidebarUsers.length ? 1 : 0,
      }}
    >
      <TopContent initial={false}>
        {state.user ? (
          <SelfAvatar
            onClick={() => history.push(`/${state.user.username}`)}
            src={`${process.env.REACT_APP_ACCELERATOR_URL}/${state.user.username}/${state.user.s3AvatarKey}.jpg`}
            alt={state.user.displayName}
          />
        ) : null}
        <TopRight>
          <Logo>
            {state.user ? (
              <>Hi, {state.user.displayName}</>
            ) : (
              <RouterLink
                to="/"
                style={{ color: '#000', textDecoration: 'none' }}
              >
                Rep.ly
              </RouterLink>
            )}
          </Logo>
          {!state.user ? <NoAuthContent /> : <AuthContent />}
        </TopRight>
      </TopContent>

      {state.user ? (
        <LoggedInNav>
          <SidebarNavItem to="/">
            <img src="/icons/home.svg" alt="Home" />
            Home
          </SidebarNavItem>
          <SidebarNavItem to={`/${state.user.username}`}>
            <img src="/icons/profile.svg" alt="Your Profile" />
            You
          </SidebarNavItem>
          <SidebarNavItem
            onClick={() => setActivityOpen(!activityOpen)}
            active={activityOpen}
          >
            <img src="/icons/activity.svg" alt="Activity" />
            Activity
          </SidebarNavItem>
        </LoggedInNav>
      ) : null}

      <Activity forwardRef={activityRef} visible={activityOpen} />

      <Desc
        style={{
          opacity: state.sidebarUsers.length > 0 ? 1 : 0,
        }}
      >
        New on Rep.ly
      </Desc>
      <AvatarContainer>
        {state.sidebarUsers.map((u) => (
          <Tooltip
            label={u.displayName}
            left={0}
            top={-12}
            key={`sidebar-avatar-${u._id}`}
          >
            <Avatar
              src={`${process.env.REACT_APP_ACCELERATOR_URL}/${u.username}/${u.s3AvatarKey}.jpg`}
              onClick={() => history.push(`/${u.username}`)}
            />
          </Tooltip>
        ))}
      </AvatarContainer>
      <NavigationContainer>
        <LinkMuted href="https://twitter.com/dipdotorg">Twitter</LinkMuted>
        <NavigationSep />
        <LinkMutedRouter to="/faq">FAQs</LinkMutedRouter>
        <NavigationSep />
        <LinkMutedRouter to="/privacy">Privacy</LinkMutedRouter>
        <NavigationSep />
        <LinkMutedRouter to="/terms">Terms</LinkMutedRouter>
      </NavigationContainer>
    </Container>
  );
}
